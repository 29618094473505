import { createContext, FC, ReactNode, useEffect, useReducer } from 'react';
import axios from '../utils/axios';

interface User {
	firstName: string;
	lastName: string;
	// TODO admin
}

interface State {
	authenticated: boolean;
	initialized: boolean;
	user: User | null;
}

export interface AuthContextValue extends State {
	updateUser: (user: User | null) => void;
}

const initialState: State = {
	authenticated: false,
	initialized: false,
	user: null,
};

export const AuthContext = createContext<AuthContextValue>({
	...initialState,
	updateUser: (user: User | null) => {}, // eslint-disable-line @typescript-eslint/no-empty-function
});

interface AuthProviderProps {
	children: ReactNode;
}

function reducer(currentState: Partial<State>, newState: Partial<State> ) {
	return { ...currentState, ...newState } as State;
}

export const AuthProvider: FC<AuthProviderProps> = (props) => {
	const { children } = props;
	const [ state, dispatch ] = useReducer(reducer, initialState);

	useEffect(() => {
		// TODO Use getProfile? Or update /status route?
		axios.get('/status')
			.then(function (response) {
				if (response.status === 200) {
					if (response.data.authenticated === true) {
						axios.get('/profile')
							.then(function (response) {
								if (response.status === 200) {
									dispatch({ authenticated: true, initialized: true, user: { firstName: response.data.general.firstName, lastName: response.data.general.lastName }});
								} else {
									// Rollbar?
									console.log('Rollbar');
								}
							})
							.catch(function (error) {
								// Rollbar?
								console.log('Rollbar');
							});
					} else if (response.data.authenticated === false) {
						dispatch({ initialized: true });
					} else {
						// Rollbar?
						console.log('Rollbar');
					}
				} else {
					// Rollbar?
					console.log('Rollbar');
				}
			})
			.catch(function (error) {
				// Rollbar?
				console.log('Rollbar');
			});
	}, []);

	const updateUser = function (user:User | null): void {
		dispatch({ initialized: true, authenticated: (user !== null), user: user });
	};

	return (
		<AuthContext.Provider
			value={{
				...state,
				updateUser: updateUser,
			}}
		>
			{children}
		</AuthContext.Provider>
	);
};
