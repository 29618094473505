import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
import { Box, Button, Card, Container, Divider, Link, TextField, Typography } from '@mui/material';
import { useFormik } from 'formik';
import toast from 'react-hot-toast';
import { useEffect, useState } from 'react';
import * as Yup from 'yup';
// import { Logo } from '../components/Logo';
import { CompactLogo } from '../components/CompactLogo';
import axios from '../utils/axios';

function ResetPassword(): JSX.Element | null {
	const navigate = useNavigate();
	const { uid, token } = useParams();
	const { t } = useTranslation();
	const [ checked, setChecked ] = useState(false);

	const formik = useFormik({
		initialValues: {
			password: '',
			passwordConfirmation: '',
		},
		validationSchema: Yup.object({
			password: Yup
				.string()
				.min(8)		// TODO Lang
				.max(255)	// TODO Lang
				.required(t('yup.newPassword.required')),
			passwordConfirmation: Yup
				.string()
				.required(t('yup.passwordConfirmation.required'))
				.oneOf([Yup.ref('password')], t('yup.passwordConfirmation.match')),
		}),
		onSubmit: async (values, helpers): Promise<void> => {
			// TODO Use custom hook?
			axios.put('/reset-password/' + uid + '/' + token, {
				password: values.password,
				// TODO Add passwordConfirmation field
			})
				.then(function (response) {
					if (response.status === 200) {
						toast.success(response.data.message);
						// TODO Navigate repeated, put in finally()
						navigate('/login');
					} else {
						// Rollbar?
						console.log('Rollbar');
					}
				})
				.catch(function (error) {
					// TODO Don't use code 400
					// Use 401/403/etc. instead
					if (error.response.status === 400) {
						toast.error(error.response.data.message);
						// TODO Navigate repeated, put in finally()
						navigate('/login');
					} else {
						// Rollbar?
						console.log('Rollbar');
					}
				});
			// TODO Cleanup
			// try {
			// 	await passwordRecovery(values.email);

			// 	if (isMounted()) {
			// 		sessionStorage.setItem('username', values.email);
			// 		router.push('/authentication/password-reset');
			// 	}
			// } catch (err) {
			// 	console.error(err);

			// 	if (isMounted()) {
			// 		helpers.setStatus({ success: false });
			// 		helpers.setErrors({ submit: err.message });
			// 		helpers.setSubmitting(false);
			// 	}
			// }
		},
	});

	useEffect(
		() => {
			// TODO Use custom hook?
			axios.get('/reset-password/' + uid + '/' + token)
				.then(function (response) {
					if (response.status === 204) {
						setChecked(true);
					} else {
						// Rollbar?
						console.log('Rollbar');
					}
				})
				.catch(function (error) {
					// TODO Don't use code 400
					// Use 401/403/etc. instead
					if (error.response.status === 400) {
						toast.error(error.response.data.message);
						// TODO Navigate repeated, put in finally()
						navigate('/login');
					} else {
						// Rollbar?
						console.log('Rollbar');
					}
				});
			// TODO Find another solution
		}, [], // eslint-disable-line react-hooks/exhaustive-deps
	);

	if (!checked) {
		return null;
	}

	return (
		<>
			<Box
				component="main"
				sx={{
					backgroundColor: 'background.default',
					display: 'flex',
					flexDirection: 'column',
					minHeight: '100vh',
				}}
			>
				<Container
					maxWidth="sm"
					sx={{
						py: {
							xs: '60px',
							md: '120px',
						},
					}}
				>
					<Card
						elevation={16}
						sx={{ p: 4 }}
					>
						<Box
							sx={{
								alignItems: 'center',
								display: 'flex',
								flexDirection: 'column',
								justifyContent: 'center',
							}}
						>
							<Link component={RouterLink} to="/">
								{/*
								<Logo
									sx={{
										height: 40,
										width: 40,
									}}
								/>
								*/}
								<CompactLogo
									height={40}
									sx={{
										mb: '6.5px',
									}}
								/>
							</Link>
							<Typography variant="h4">
								{ t('pages.resetPassword.title') }
							</Typography>
							<Typography
								color="textSecondary"
								sx={{ mt: 2 }}
								variant="body2"
							>
								{t('pages.resetPassword.instructions')}
							</Typography>
						</Box>
						<Box
							sx={{
								flexGrow: 1,
								mt: 3,
							}}
						>
							<form
								noValidate
								onSubmit={formik.handleSubmit}
							>
								<TextField
									error={Boolean(formik.touched.password && formik.errors.password)}
									fullWidth
									helperText={formik.touched.password && formik.errors.password}
									label={t('pages.resetPassword.newPassword')}
									margin="normal"
									name="password"
									onBlur={formik.handleBlur}
									onChange={formik.handleChange}
									required
									type="password"
									value={formik.values.password}
								/>
								<TextField
									error={Boolean(formik.touched.passwordConfirmation && formik.errors.passwordConfirmation)}
									fullWidth
									helperText={formik.touched.passwordConfirmation && formik.errors.passwordConfirmation}
									label={t('pages.resetPassword.passwordConfirmation')}
									margin="normal"
									name="passwordConfirmation"
									onBlur={formik.handleBlur}
									onChange={formik.handleChange}
									required
									type="password"
									value={formik.values.passwordConfirmation}
								/>
								<Box sx={{ mt: 3 }}>
									<Button
										disabled={formik.isSubmitting}
										fullWidth
										size="large"
										type="submit"
										variant="contained"
									>
										{t('pages.resetPassword.resetPassword')}
									</Button>
								</Box>
							</form>
						</Box>
						<Divider sx={{ my: 3 }} />
						<Button
							component={RouterLink}
							fullWidth
							size="large"
							to="/login"
							variant="outlined"
						>
							{ t('pages.resetPassword.backButton') }
						</Button>
					</Card>
				</Container>
			</Box>
		</>
	);
}

export default ResetPassword;
