import { useState } from 'react';
import type { FC } from 'react';
import { Outlet } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { DashboardNavbar } from './DashboardNavbar';
import { DashboardSidebar } from './DashboardSidebar';
import { Box } from '@mui/material';

const DashboardLayoutRoot = styled('div')(
	({ theme }) => ({
		display: 'flex',
		flex: '1 1 auto',
		maxWidth: '100%',
		paddingTop: 64,
		[theme.breakpoints.up('lg')]: {
			paddingLeft: 280,
		},
	}),
);

export const DashboardLayout: FC = () => {
	const [isSidebarOpen, setIsSidebarOpen] = useState<boolean>(false);

	return (
		<>
			<DashboardLayoutRoot>
				<Box
					sx={{
						display: 'flex',
						flex: '1 1 auto',
						flexDirection: 'column',
						width: '100%',
					}}
				>
					<Outlet />
				</Box>
			</DashboardLayoutRoot>
			<DashboardNavbar onOpenSidebar={(): void => setIsSidebarOpen(true)} />
			<DashboardSidebar
				onClose={(): void => setIsSidebarOpen(false)}
				open={isSidebarOpen}
			/>
		</>
	);
};
