import { useState, type FC } from 'react';
import { Link as RouterLink} from 'react-router-dom';
import { Box, IconButton, Typography } from '@mui/material';
import { amber } from '@mui/material/colors';
import { Star as StarIcon } from '../../assets/icons/star';
import { StarOutlined as StarOutlinedIcon } from '../../assets/icons/star-outlined';
import type { Activity } from './Types';
import axios from '../../utils/axios';

interface ActivityProps {
	activity: Activity;
}

export const ActivityItem: FC<ActivityProps> = (props) => {
	const { activity, ...other } = props;
	const [ starred, setStarred ] = useState(activity.starred);

	const handleStarToggle = (): void => {
		// TODO Use custom hook?
		axios.put('/activities/' + activity.uid + '/star')
			.then(function (response) {
				if (response.status === 200) {
					setStarred(response.data.starred);
				} else {
					// Rollbar?
					console.log('Rollbar');
				}
			})
			.catch(function (error) {
				// Rollbar?
				console.log('Rollbar');
			});
	};

	return (
		<Box
			sx={{
				alignItems: 'center',
				backgroundColor: 'background.paper',
				borderBottomWidth: 1,
				borderBottomStyle: 'solid',
				borderBottomColor: 'divider',
				display: 'flex',
				'&:hover': {
					backgroundColor: 'action.hover',
				},
			}}
			{...other}
		>
			<Box
				sx={{
					alignItems: 'center',
					display: {
						md: 'flex',
					},
					ml: 2,
					mr: 1,
				}}
			>
				{/* TODO Lang */}
				{/* <Tooltip title="Favori"> */}
				<IconButton onClick={handleStarToggle}>
					{
						starred
							? (
								<StarIcon
									fontSize="small"
									sx={{ color: amber[400] }}
								/>
							)
							: (
								<StarOutlinedIcon fontSize="small" />
							)
					}
				</IconButton>
				{/* </Tooltip> */}
			</Box>
			<Box
				component={RouterLink}
				sx={{
					alignItems: 'center',
					display: 'flex',
					flexGrow: 1,
					flexWrap: {
						xs: 'wrap',
						md: 'nowrap',
					},
					py: 3,
					textDecoration: 'none',
				}}
				to={'/activities/' + activity.uid}
			>
				<Box
					sx={{
						flexGrow: 1,
						ml: {
							xs: 0,
							md: 2,
						},
						my: {
							xs: 2,
							md: 0,
						},
						overflow: 'hidden',
						width: {
							xs: '100%',
							md: 'auto',
						},
					}}
				>
					<Box
						sx={{
							alignItems: 'center',
							display: 'flex',
							maxWidth: 800,
							width: '100%',
						}}
					>
						<Typography
							color="textPrimary"
							sx={{
								fontWeight: 600,
								minWidth: 100,
								// maxWidth: 400,
								mr: 1,
							}}
							// noWrap
							variant="body2"
						>
							{ activity.name }
						</Typography>
					</Box>
				</Box>
			</Box>
		</Box>
	);
};
