import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { Box, Button, Card, Container, Divider, FormHelperText, Link, TextField, Typography } from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { GuestGuard } from '../components/GuestGuard';
// import { Logo } from '../components/Logo';
import { CompactLogo } from '../components/CompactLogo';
import axios from '../utils/axios';
import { useAuth } from '../hooks/useAuth';

// FIXME Bug when trying login two times in parrallele (bug #1)
// FIXME and then clicking on forgot password, redirecting to dashboard, and authcontext not authenticated (bug #2)
// FIXME At inverse, when logout then performing an action that auth is required, it crash instead of rediect with message. (bug #3)

function Login(): JSX.Element {
	const { updateUser } = useAuth();
	const navigate = useNavigate();
	const { t } = useTranslation();

	const formik = useFormik({
		initialValues: {
			// TODO Use environment constant for 'development'
			email: '',
			password: '',
			submit: null,
		},
		validationSchema: Yup.object({
			email: Yup
				.string()
				.email(t('yup.email.valid'))
				.max(255)	// TODO Lang
				.required(t('yup.email.required')),
			password: Yup
				.string()
				.max(255)	// TODO Lang
				.required(t('yup.password.required')),
		}),
		onSubmit: async (values, helpers): Promise<void> => {
			// TODO Use custom hook?
			axios.post('/login', {
				email: values.email,
				password: values.password,
			})
				.then(function (response) {
					if (response.status === 204) {
						// TODO Fix it
						axios.get('/profile')
							.then(function (response) {
								if (response.status === 200) {
									updateUser({firstName: response.data.general.firstName, lastName: response.data.general.lastName});
									navigate('/dashboard');
								} else {
									// Rollbar?
									console.log('Rollbar');
								}
							})
							.catch(function (error) {
								// Rollbar?
								console.log('Rollbar');
							});
					} else {
						// Rollbar?
						console.log('Rollbar');
					}
				})
				.catch(function (error) {
					if (error.response.status === 401) {
						// TODO
						helpers.setStatus({ success: false });
						// TODO Get message from backend
						helpers.setErrors({ submit: 'Incorrect email or password.' });
						helpers.setSubmitting(false);
					} else {
						// Rollbar?
						console.log('Rollbar');
					}
				});
		},
	});

	return (
		<GuestGuard>
			<Box
				component="main"
				sx={{
					backgroundColor: 'background.default',
					display: 'flex',
					flexDirection: 'column',
					minHeight: '100vh',
				}}
			>
				<Container
					maxWidth="sm"
					sx={{
						py: {
							xs: '60px',
							md: '120px',
						},
					}}
				>
					<Card
						elevation={16}
						sx={{ p: 4 }}
					>
						<Box
							sx={{
								alignItems: 'center',
								display: 'flex',
								flexDirection: 'column',
								justifyContent: 'center',
							}}
						>
							<Link component={RouterLink} to="/">
								{/*
								<Logo
									sx={{
										height: 40,
										width: 40,
									}}
								/>
								*/}
								<CompactLogo
									height={40}
									sx={{
										mb: '6.5px',
									}}
								/>
							</Link>
							<Typography variant="h4">
								{t('pages.login.title')}
							</Typography>
							<Typography
								color="textSecondary"
								sx={{ mt: 2 }}
								variant="body2"
							>
								{t('pages.login.instructions')}
							</Typography>
						</Box>
						<Box
							sx={{
								flexGrow: 1,
								mt: 3,
							}}
						>
							<form
								noValidate
								onSubmit={formik.handleSubmit}
							>
								<TextField
									autoFocus
									error={Boolean(formik.touched.email && formik.errors.email)}
									fullWidth
									helperText={formik.touched.email && formik.errors.email}
									label={t('pages.login.email')}
									margin="normal"
									name="email"
									// TODO Best way to avoid error if page change?
									// onBlur={formik.handleBlur}
									onChange={formik.handleChange}
									required
									type="email"
									value={formik.values.email}
								/>
								<TextField
									error={Boolean(formik.touched.password && formik.errors.password)}
									fullWidth
									helperText={formik.touched.password && formik.errors.password}
									label={t('pages.login.password')}
									margin="normal"
									name="password"
									onBlur={formik.handleBlur}
									onChange={formik.handleChange}
									required
									type="password"
									value={formik.values.password}
								/>
								<Box sx={{ textAlign: 'right' }}>
									<Link
										color="textSecondary"
										component={RouterLink}
										sx={{ mt: 1 }}
										variant="body2"
										to="/forgot-password"
									>
										{t('pages.login.forgotPassword')}
									</Link>
								</Box>
								{formik.errors.submit && (
									<Box sx={{ mt: 3 }}>
										<FormHelperText error>
											{formik.errors.submit}
										</FormHelperText>
									</Box>
								)}
								<Box sx={{ mt: 2 }}>
									<Button
										disabled={formik.isSubmitting}
										fullWidth
										size="large"
										type="submit"
										variant="contained"
									>
										{t('pages.login.login')}
									</Button>
								</Box>
							</form>
						</Box>
						<Divider sx={{ my: 3 }} />
						<Button
							component={RouterLink}
							fullWidth
							size="large"
							to="/register"
							variant="outlined"
						>
							{t('pages.login.createAccount')}
						</Button>
					</Card>
				</Container>
			</Box>
		</GuestGuard>
	);
}

export default Login;
