import { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Box, Button, Card, CardContent, Container, Divider, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import axios from '../utils/axios';
import { CompactLogo } from '../components/CompactLogo';

function Changelog(): JSX.Element | null {
	return (
		<>
			<Box
				component="main"
				sx={{
					flexGrow: 1,
					py: 8,
				}}
			>
				<Container maxWidth="sm">
					<Box
						sx={{
							alignItems: 'center',
							display: 'flex',
							justifyContent: 'space-between',
						}}
					>
						<Typography variant="h4">
							Changelog
						</Typography>
						<CompactLogo height={42} />
					</Box>
					<Divider sx={{ mt: 3 }} />
					<Card sx={{ mt: 3 }}>
						<CardContent>
							<Box
								sx={{
									alignItems: 'center',
									display: 'flex',
									justifyContent: 'space-between',
								}}
							>
								<Typography variant="h5">
									Version 0.4.0
								</Typography>
								<Typography
									color="textSecondary"
									variant="body2"
								>
									13 octobre 2023
								</Typography>
							</Box>
							<ul>
								<li>
									Banque d'activités
									<ul>
										<li><Typography color="textSecondary">Système de votes sur les compétences</Typography></li>
									</ul>
								</li>
								<br />
								<li>
									Ajout du changelog
								</li>
								<br />
								<li>
									Corrections de bugs
								</li>
							</ul>
						</CardContent>
					</Card>
					<Card sx={{ mt: 3 }}>
						<CardContent>
							<Box
								sx={{
									alignItems: 'center',
									display: 'flex',
									justifyContent: 'space-between',
								}}
							>
								<Typography variant="h5">
									Version 0.3.0
								</Typography>
								<Typography
									color="textSecondary"
									variant="body2"
								>
									28 septembre 2023
								</Typography>
							</Box>
							<ul>
								<li>
									Banque d'activités
									<ul>
										<li><Typography color="textSecondary">Ajout d'une activité</Typography></li>
										<li><Typography color="textSecondary">Système de favoris</Typography></li>
										<li><Typography color="textSecondary">Filtre de base (mot clés, favoris)</Typography></li>
									</ul>
								</li>
								<br />
								<li>
									Module des apprentis
									<ul>
										<li><Typography color="textSecondary">Système d'avatars</Typography></li>
										<li><Typography color="textSecondary">Début/Fin des cycles défini par une date fixe</Typography></li>
									</ul>
								</li>
								<br />
								<li>
									Corrections de bugs
								</li>
							</ul>
						</CardContent>
					</Card>
					<Card sx={{ mt: 3 }}>
						<CardContent>
							<Box
								sx={{
									alignItems: 'center',
									display: 'flex',
									justifyContent: 'space-between',
								}}
							>
								<Typography variant="h5">
									Version 0.2.0
								</Typography>
								<Typography
									color="textSecondary"
									variant="body2"
								>
									13 mars 2023
								</Typography>
							</Box>
							<ul>
								<li>
									Module de suggestions
									<ul>
										<li><Typography color="textSecondary">Étiquettes de status</Typography></li>
									</ul>
								</li>
							</ul>
						</CardContent>
					</Card>
					<Card sx={{ mt: 3 }}>
						<CardContent>
							<Box
								sx={{
									alignItems: 'center',
									display: 'flex',
									justifyContent: 'space-between',
								}}
							>
								<Typography variant="h5">
									Version 0.1.0
								</Typography>
								<Typography
									color="textSecondary"
									variant="body2"
								>
									25 février 2023
								</Typography>
							</Box>
							<ul>
								<li>
									Module d'authentification
									<ul>
										<li><Typography color="textSecondary">Connexion/Déconnexion</Typography></li>
										<li><Typography color="textSecondary">Inscription</Typography></li>
										<li><Typography color="textSecondary">Oubli de mot de passe / Réinitialisation de mot de passe</Typography></li>
										<li><Typography color="textSecondary">Confirmation de l'adresse courriel</Typography></li>
									</ul>
								</li>
								<br />
								<li>
									Page de profil
									<ul>
										<li><Typography color="textSecondary">Modification des informations de base</Typography></li>
										<li><Typography color="textSecondary">Modification du mot de passe</Typography></li>
									</ul>
								</li>
								<br />
								<li>
									Module des apprentis
									<ul>
										<li><Typography color="textSecondary">Listage</Typography></li>
										<li><Typography color="textSecondary">Ajout d'un apprenti</Typography></li>
										<li><Typography color="textSecondary">Modification d'un apprenti</Typography></li>
										<li><Typography color="textSecondary">Suppression d'un apprenti</Typography></li>
									</ul>
								</li>
								<br />
								<li>Tableau de bord
								<ul>
									<li><Typography color="textSecondary">Barre de progrès selon le temps du cycle en cours</Typography></li>
									<li><Typography color="textSecondary">Visualisation des compétences par matière selon le cycle en cours</Typography></li>
									</ul>
								</li>
								<br />
								<li>
									Module de suggestions
									<ul>
										<li><Typography color="textSecondary">Listage des suggestions</Typography></li>
										<li><Typography color="textSecondary">Vote sur les suggestions</Typography></li>
										<li><Typography color="textSecondary">Commentaires sur les suggestions</Typography></li>
									</ul>
								</li>
							</ul>
						</CardContent>
					</Card>
				</Container>
			</Box>
		</>
	);
}

export default Changelog;
