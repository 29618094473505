import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import { Box, Button, Card, Container, Divider, FormHelperText, Link, TextField, Typography } from '@mui/material';
import { useFormik } from 'formik';
import toast from 'react-hot-toast';
import * as Yup from 'yup';
import { GuestGuard } from '../components/GuestGuard';
// import { Logo } from '../components/Logo';
import { CompactLogo } from '../components/CompactLogo';
import axios from '../utils/axios';

function ForgotPassword(): JSX.Element {
	const { t } = useTranslation();

	const formik = useFormik({
		initialValues: {
			email: '',
			submit: null,
		},
		validationSchema: Yup.object({
			email: Yup
				.string()
				.email(t('yup.email.valid'))
				.max(255)	// TODO Lang
				.required(t('yup.email.required')),
		}),
		onSubmit: async (values, helpers): Promise<void> => {
			// TODO Use custom hook?
			axios.put('/forgot-password', {
				email: values.email,
			})
				.then(function (response) {
					if (response.status === 200) {
						toast.success(response.data.message);
						formik.resetForm();
					} else {
						// Rollbar?
						console.log('Rollbar');
					}
				})
				.catch(function (error) {
					// Rollbar?
					console.log('Rollbar');
				});
			// TODO Cleanup
			// try {
			// 	await passwordRecovery(values.email);

			// 	if (isMounted()) {
			// 		sessionStorage.setItem('username', values.email);
			// 		router.push('/authentication/password-reset');
			// 	}
			// } catch (err) {
			// 	console.error(err);

			// 	if (isMounted()) {
			// 		helpers.setStatus({ success: false });
			// 		helpers.setErrors({ submit: err.message });
			// 		helpers.setSubmitting(false);
			// 	}
			// }
		},
	});

	return (
		<GuestGuard>
			<Box
				component="main"
				sx={{
					backgroundColor: 'background.default',
					display: 'flex',
					flexDirection: 'column',
					minHeight: '100vh',
				}}
			>
				<Container
					maxWidth="sm"
					sx={{
						py: {
							xs: '60px',
							md: '120px',
						},
					}}
				>
					<Card
						elevation={16}
						sx={{ p: 4 }}
					>
						<Box
							sx={{
								alignItems: 'center',
								display: 'flex',
								flexDirection: 'column',
								justifyContent: 'center',
							}}
						>
							<Link component={RouterLink} to="/">
								{/*
								<Logo
									sx={{
										height: 40,
										width: 40,
									}}
								/>
								*/}
								<CompactLogo
									height={40}
									sx={{
										mb: '6.5px',
									}}
								/>
							</Link>
							<Typography variant="h4">
								{ t('pages.forgotPassword.title') }
							</Typography>
							<Typography
								color="textSecondary"
								sx={{ mt: 2 }}
								variant="body2"
							>
								{t('pages.forgotPassword.instructions')}
							</Typography>
						</Box>
						<Box
							sx={{
								flexGrow: 1,
								mt: 3,
							}}
						>
							<form
								noValidate
								onSubmit={formik.handleSubmit}
							>
								<TextField
									error={Boolean(formik.touched.email && formik.errors.email)}
									fullWidth
									helperText={formik.touched.email && formik.errors.email}
									label={t('pages.forgotPassword.email')}
									margin="normal"
									name="email"
									onBlur={formik.handleBlur}
									onChange={formik.handleChange}
									required
									type="email"
									value={formik.values.email}
								/>
								{formik.errors.submit && (
									<Box sx={{ mt: 3 }}>
										<FormHelperText error>
											{formik.errors.submit}
										</FormHelperText>
									</Box>
								)}
								<Box sx={{ mt: 3 }}>
									<Button
										disabled={formik.isSubmitting}
										fullWidth
										size="large"
										type="submit"
										variant="contained"
									>
										{t('pages.forgotPassword.sendLink')}
									</Button>
								</Box>
							</form>
						</Box>
						<Divider sx={{ my: 3 }} />
						<Button
							component={RouterLink}
							fullWidth
							size="large"
							to="/login"
							variant="outlined"
						>
							{ t('pages.forgotPassword.backButton') }
						</Button>
					</Card>
				</Container>
			</Box>
		</GuestGuard>
	);
}

export default ForgotPassword;
