import { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Box, Button, Container, Typography, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import axios from '../utils/axios';
import { FullLogo } from '../components/FullLogo';

function Home(): JSX.Element | null {
	const [button, setButton] = useState<JSX.Element | null>(null);
	const theme = useTheme();
	const mobileDevice = useMediaQuery(theme.breakpoints.down('sm'));

	useEffect(
		() => {
			// TODO Use custom hook?
			axios.get('/status')
				.then(function (response) {
					if (response.status === 200) {
						if (response.data.authenticated === true) {
							// TODO Lang
							setButton(<Button component={RouterLink} to="/dashboard" size="large" variant="contained">Aller sur le tableau de bord</Button>);
						} else if (response.data.authenticated === false) {
							// TODO Lang
							setButton(<Button component={RouterLink} to="/login" size="large" variant="contained">Aller sur la page de connexion</Button>);
						} else {
							// Rollbar?
							console.log('Rollbar');
						}
					} else {
						// Rollbar?
						console.log('Rollbar');
					}
				})
				.catch(function (error) {
					// Rollbar?
					console.log('Rollbar');
				});
		}, [],
	);

	if (button === null) {
		return null;
	}

	return (
		<>
			<Box
				component="main"
				sx={{
					backgroundColor: 'background.paper',
					display: 'flex',
					flexGrow: 1,
					py: '80px',
				}}
			>
				<Container maxWidth="lg">
					<Typography
						align="center"
						variant={mobileDevice ? 'h4' : 'h1'}
					>
						{/* TODO Lang */}
						Bienvenue sur
						{' '}
						<Typography
							component='span'
							variant={mobileDevice ? 'h4' : 'h1'}
							sx={{
								color: theme.palette.mode === 'dark' ? theme.palette.primary.dark : theme.palette.primary.light,
							}}>
							{/* TODO Constant for app name */}
							Apprenti Parcours
						</Typography>!
					</Typography>
					{/* <Typography
						align="center"
						color="textSecondary"
						sx={{ mt: 0.5 }}
						variant="subtitle2"
					>
						You either tried some shady route or you
						came here by mistake. Whichever it is, try using the
						navigation.
					</Typography> */}
					<Box
						sx={{
							display: 'flex',
							justifyContent: 'center',
							mt: 6,
						}}
					>
						<FullLogo sx={{ my: 5 }} />
					</Box>
					<Box
						sx={{
							display: 'flex',
							justifyContent: 'center',
							mt: 6,
						}}
					>
						{ button }
					</Box>
				</Container>
			</Box>
		</>
	);
}

export default Home;
