import type { FC, MutableRefObject } from 'react';
import { Box, Button, Drawer, FormControl, FormControlLabel, InputAdornment, Radio, RadioGroup, TextField, useMediaQuery } from '@mui/material';
import type { Theme } from '@mui/material';
import { Clear as ClearIcon, Star as StarIcon, StarHalf as StarHalfIcon, StarOutline as StarOutlineIcon } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import { Search as SearchIcon } from '../../assets/icons/search';

interface MailSidebarProps {
	containerRef: MutableRefObject<HTMLDivElement | null>;
	filters: { q: string, starred: string };
	handleChangeFilters: (filters: { q: string, starred: string }) => void;
	open?: boolean;
	onClose?: () => void;
}

const MailSidebarDesktop = styled(Drawer)({
	flexShrink: 0,
	width: 280,
	'& .MuiDrawer-paper': {
		position: 'relative',
		width: 280,
	},
});

const MailSidebarMobile = styled(Drawer)({
	width: 280,
	'& .MuiDrawer-paper': {
		top: 64,
		height: 'calc(100% - 64px)',
		width: 280,
	},
});

export const MailSidebar: FC<MailSidebarProps> = (props) => {
	const { containerRef, filters, handleChangeFilters, onClose, open, ...other } = props;
	const mdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));

	const content = (
		<Box sx={{ p: 2 }}>
			<TextField
				fullWidth
				InputProps={{
					startAdornment: (
						<InputAdornment position="start">
							<SearchIcon fontSize="small" />
						</InputAdornment>
					),
				}}
				onChange={(event) => { handleChangeFilters({ q: event.target.value, starred: filters.starred }); }}
				// TODO Lang
				placeholder="compter, lire, ..."
				size="small"
				value={filters.q}
			/>
			<FormControl sx={{ mt: 2 }} >
				{/* <FormLabel id="starred-title">Favoris</FormLabel> */}
				<RadioGroup
					row
					aria-labelledby="starred-title"
					name="starred"
					onChange={(event) => {
						handleChangeFilters({ q: filters.q, starred: event.target.value });
					}}
					sx={{ ml: 2 }}
					value={filters.starred}
				>
					<FormControlLabel value="" control={<Radio />} label={<StarHalfIcon sx={{ mt: 0.5 }} />} />
					<FormControlLabel value="1" control={<Radio />} label={<StarIcon sx={{ mt: 0.5 }} />} />
					<FormControlLabel value="0" control={<Radio />} label={<StarOutlineIcon sx={{ mt: 0.5 }} />} />
				</RadioGroup>
			</FormControl>
			<Button
				fullWidth
				onClick={() => handleChangeFilters({ q: '', starred: ''})}
				startIcon={<ClearIcon />}
				sx={{ mt: 2 }}
				variant="contained"
			>
				{/* TODO LANG */}
				Réinitialiser les filtres
			</Button>
		</Box>
	);

	if (mdUp) {
		return (
			<MailSidebarDesktop
				anchor="left"
				open={open}
				SlideProps={{ container: containerRef?.current }}
				variant="persistent"
				{...other}
			>
				{content}
			</MailSidebarDesktop>
		);
	}

	return (
		<MailSidebarMobile
			anchor="left"
			ModalProps={{ container: containerRef?.current }}
			onClose={onClose}
			open={open}
			SlideProps={{ container: containerRef?.current }}
			variant="temporary"
			{...other}
		>
			{content}
		</MailSidebarMobile>
	);
};
