const Configuration: {
	backendUrl: string,
	environment: 'development' | 'demo' | 'production',
	port: number,
	title: string,
} = {
	backendUrl: process.env.REACT_APP_BACKEND_URL || 'http://localhost:3000',
	environment: (process.env.REACT_APP_ENVIRONMENT === 'development' || process.env.REACT_APP_ENVIRONMENT === 'demo' || process.env.REACT_APP_ENVIRONMENT === 'production') ? process.env.REACT_APP_ENVIRONMENT : 'development',
	port: parseInt(process.env.PORT || '', 10) || 3000,
	// TODO Best place to set the application title?
	title: process.env.REACT_APP_TITLE || 'Demo App',
};

export { Configuration };
