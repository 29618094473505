import moment from 'moment';
import { ArrowDownward as ArrowDownwardIcon, ArrowUpward as ArrowUpwardIcon, ExpandMore as ExpandMoreIcon } from '@mui/icons-material';
import { Accordion, AccordionDetails, AccordionSummary, Alert, Avatar, Box, Button, Card, CardContent, CardHeader, Collapse, Container, Divider, LinearProgress, Typography, useTheme } from '@mui/material';
import { Fragment, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link as RouterLink} from 'react-router-dom';
import { Users  as UsersIcon} from '../assets/icons/users';
import { AuthGuard } from '../components/AuthGuard';
import axios from '../utils/axios';

type Competency = {
	name: string,
	outcome: string,
};

type Subject = {
	domain: string;
	competencies: Competency[];
	name: string;
};

type ApprenticeCycle = {
	apprentice: {
		avatar?: {
			color?: string,
			data: string,
		},
		firstName: string,
		uid: string,
	},
	cycle: {
		code: string,
		from: number,
		to: number,
	},
	subjects: Subject[],
};

// TODO Clean apprentices cycles list, convert some parts to components
function Dashboard(): JSX.Element | null {
	const theme = useTheme();
	const { t } = useTranslation();
	const [ apprenticeCycles, setApprenticeCycles ] = useState<ApprenticeCycle[] | null>(null);
	const [ displayList, setDisplayList ] = useState<{ [key: string]: boolean }>({});

	useEffect(
		() => {
			// TODO Use custom hook?
			axios.get('/dashboard/apprentice-cycles')
				.then(function (response) {
					if (response.status === 200) {
						response.data.forEach((element: ApprenticeCycle) => {
							setDisplayList({ ...displayList, [element.apprentice.uid]: false });
						});
						setApprenticeCycles(response.data);
					} else {
						// Rollbar?
						console.log('Rollbar');
					}
				})
				.catch(function (error) {
					// Rollbar?
					console.log('Rollbar');
				});
			// TODO Find another solution
		}, [], // eslint-disable-line react-hooks/exhaustive-deps
	);

	if (apprenticeCycles === null) {
		return null;
	}

	return (
		// TODO Put guard before Dashboard displays
		<AuthGuard>
			<Box
				component="main"
				sx={{
					flexGrow: 1,
					py: 8,
				}}
			>
				<Container maxWidth="md">
					<Card>
						<CardContent>
							<Typography variant="h5">
								{/* TODO Lang */}
								Bienvenue sur
								{' '}
								<Typography
									component='span'
									variant="h5"
									sx={{
										color: theme.palette.mode === 'dark' ? theme.palette.primary.dark : theme.palette.primary.light,
									}}>
									{/* TODO Constant for app name */}
									Apprenti Parcours
								</Typography>!
							</Typography>
						</CardContent>
					</Card>
					{/* TODO Use Grid */}
					{ apprenticeCycles.length === 0 ? (
						<Card
							sx={{
								background: 'none',
								border: 10,
								borderColor: 'background.paper',
								borderStyle: 'dashed',
								mt: 4,
								p: 4,
								textAlign: 'center',
							}}
						>
							<Typography
								variant="h5"
							>
								{/* TODO Lang */}
								Aucune information disponible
							</Typography>
							<Typography
								color="textSecondary"
								component={'div'}
								variant="body2"
								sx={{ mt: 2 }}
							>
								Vous devez avoir un ou plusieurs apprentis liés à un cycle<br/>pour voir leur progression
							</Typography>
							<Button
								color="secondary"
								component={RouterLink}
								size="large"
								startIcon={<UsersIcon fontSize="small" />}
								to="/apprentices"
								variant="contained"
								sx={{ mt: 3 }}
							>
								{/* TODO Lang */}
								Voir mes apprentis
							</Button>
						</Card>
					) : (
						<Card sx={{ mt: 4 }}>
							<CardHeader
								title={t('pages.dashboard.apprenticeCycles')}
							/>
							{apprenticeCycles.map(info => {
								const start = (moment(info.cycle.from).toDate().getTime());
								const now = (moment().toDate().getTime());
								const end = (moment(info.cycle.to).toDate().getTime());
								const progress = (now < start) ? 0 : ((now > end) ? 100 : Math.round(((now - start) / (end - start)) * 1000) / 10);

								return (<Fragment key={info.apprentice.uid}>
									<Divider />
									<CardContent>
										<Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
											<Box
												sx={{
													p: 1,
													backgroundColor: (theme) => theme.palette.mode === 'dark'
														? 'neutral.800'
														: 'neutral.200',
													borderRadius: 2,
													width: 100,
												}}
											>
												<Typography
													align="center"
													color="textSecondary"
													variant="subtitle2"
												>
													{moment(info.cycle.from).format(t('pages.dashboard.cycleDateFormat'))}
												</Typography>
												<Typography
													align="center"
													color="textSecondary"
													variant="h6"
												>
													{moment(info.cycle.from).format('YYYY')}
												</Typography>
											</Box>
											<Box
												sx={{
													alignItems: 'end',
													display: 'flex',
													textAlign: 'center',
												}}
											>
												<Box>
													<Avatar
														src={info.apprentice.avatar && info.apprentice.avatar.data}
														sx={{
															bgcolor: info.apprentice.avatar?.color,
															height: 48,
															width: 48,
														}}
													/>
												</Box>
												<Box sx={{ mx: 2 }}>
													<Typography
														variant="h6"
													>
														{info.apprentice.firstName}
													</Typography>
													<Typography
														color="textSecondary"
														variant="overline"
														sx={{ lineHeight: 0 }}
													>
														<Trans
															i18nKey={`pages.dashboard.cycles.${info.cycle.code}`}
															components={[<></>,<sup></sup>,<></>]}
														/>
													</Typography>
												</Box>
												<Box sx={{width: 48, height: 48 }}></Box>
											</Box>
											<Box
												sx={{
													p: 1,
													backgroundColor: (theme) => theme.palette.mode === 'dark'
														? 'neutral.800'
														: 'neutral.200',
													borderRadius: 2,
													width: 100,
												}}
											>
												<Typography
													align="center"
													color="textSecondary"
													variant="subtitle2"
												>
													{moment(info.cycle.to).format(t('pages.dashboard.cycleDateFormat'))}
												</Typography>
												<Typography
													align="center"
													color="textSecondary"
													variant="h6"
												>
													{moment(info.cycle.to).format('YYYY')}
												</Typography>
											</Box>
										</Box>
										<LinearProgress
											color="secondary"
											sx={{ borderRadius: 5, height: 10, mt: 3 }}
											value={progress}
											variant="determinate"
										/>
										{info.subjects.length === 0 ? (
											// TODO Lang
											<Alert severity="info"  sx={{ mt: 2 }}>Aucune attente en fin de cycle n'est disponible pour ce cycle dans cette version de l'application.</Alert>
										) : (
											<>
												<Box textAlign={'center'} sx={{ mt: 2 }}>
													{displayList[info.apprentice.uid] === true ? (
														<Button
															endIcon={<ArrowUpwardIcon fontSize="small" />}
															onClick={() => { const list = displayList; list[info.apprentice.uid] = false; setDisplayList({...list}); }}
														>
															{/* TODO Lang */}
															Cacher les attentes en fin de cycle
														</Button>
													) : (
														<Button
															endIcon={<ArrowDownwardIcon fontSize="small" />}
															onClick={() => { const list = displayList; list[info.apprentice.uid] = true; setDisplayList({...list}); }}
														>
															{/* TODO Lang */}
															Afficher les attentes en fin de cycle
														</Button>
													)}
												</Box>
												<Collapse in={displayList[info.apprentice.uid] === true}>
													<Box sx={{ mt: 2 }}>
														{info.subjects.map((subject: Subject) =>
															<Accordion
																// TODO Edit key correctly
																key={info.apprentice.uid + '-' + subject.name}
																sx={{
																	backgroundColor: 'neutral.800',
																}}
															>
																<AccordionSummary
																	expandIcon={<ExpandMoreIcon />}
																	sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}
																>
																	<Typography variant='h6'>
																		{subject.name}
																	</Typography>
																	<Typography textAlign={'right'} variant='body2' sx={{ color: 'text.secondary', flexGrow: 1, mr: 1 }}>{subject.domain}</Typography>
																</AccordionSummary>
																<AccordionDetails>
																	{subject.competencies.map((competency: Competency) =>
																		// TODO Edit key correctly
																		<Fragment key={info.apprentice.uid + '-' + subject.name + '-' + competency.name}>
																			<Typography variant="overline">{competency.name}</Typography>
																			<Typography variant='body2' color="textSecondary">{competency.outcome}</Typography>
																		</Fragment>,
																	)}
																</AccordionDetails>
															</Accordion>,
														)}
													</Box>
												</Collapse>
											</>
										)}
									</CardContent>
								</Fragment>);
							})}
						</Card>
					)}
				</Container>
			</Box>
		</AuthGuard>
	);
}

export default Dashboard;
