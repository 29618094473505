import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import {
	Box,
	Button,
	Dialog,
	Divider,
	Typography,
} from '@mui/material';

interface ConfirmDialogProps {
	cancelText?: string;
	confirmText?: string;
	content?: JSX.Element;
	onClose?: () => void;
	onConfirm?: () => void;
	open?: boolean;
	title?: string;
}

export const ConfirmDialog: FC<ConfirmDialogProps> = (props) => {
	const { t } = useTranslation();
	const {
		cancelText,
		confirmText,
		content,
		onClose,
		onConfirm,
		open,
		title,
		// ...other
	} = props;

	return (
		<Dialog
			fullWidth
			maxWidth="sm"
			onClose={onClose}
			open={open ?? false}
		>
			<Box sx={{ px: 3, pt: 3 }}>
				<Typography
					align="center"
					variant="h5"
				>
					{title ?? t('components.confirmDialog.title')}
				</Typography>
			</Box>
			<Box sx={{ p: 3 }}>
				{content ?? t('components.confirmDialog.content')}
			</Box>
			<Divider />
			<Box
				sx={{
					alignItems: 'center',
					display: 'flex',
					p: 2,
				}}
			>
				<Box sx={{ flexGrow: 1 }} />
				<Button onClick={onClose}>
					{cancelText ?? t('components.confirmDialog.cancel')}
				</Button>
				<Button
					onClick={onConfirm}
					sx={{ ml: 1 }}
					variant="contained"
				>
					{confirmText ?? t('components.confirmDialog.confirm')}
				</Button>
			</Box>
		</Dialog>
	);
};
