import { useEffect, useRef, useState } from 'react';
import { Link as RouterLink, useSearchParams } from 'react-router-dom';
import { Box, Button, Typography, useMediaQuery } from '@mui/material';
import type { Theme } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { styled } from '@mui/material/styles';
import { MailList } from './List';
import { MailSidebar } from './Sidebar';

const MailInner = styled(
	'div',
	{ shouldForwardProp: (prop) => prop !== 'open' },
)<{ open?: boolean; }>(
	({ theme, open }) => ({
		flexGrow: 1,
		overflow: 'hidden',
		[theme.breakpoints.up('md')]: {
			marginLeft: -280,
		},
		transition: theme.transitions.create('margin', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
		...(open && {
			[theme.breakpoints.up('md')]: {
				marginLeft: 0,
			},
			transition: theme.transitions.create('margin', {
				easing: theme.transitions.easing.easeOut,
				duration: theme.transitions.duration.enteringScreen,
			}),
		}),
	}),
);

function ActivitiesPage(): JSX.Element {
	const [ searchParams, setSearchParams ] = useSearchParams();
	const rootRef = useRef<HTMLDivElement | null>(null);
	const [ filters, setFilters ] = useState<{ q: string, starred: string }>({
		q: searchParams.get('q') !== null ? searchParams.get('q') as string : '',
		starred: searchParams.get('starred') !== null ? searchParams.get('starred') as string : '',
	});
	const [ isSidebarOpen, setSidebarOpen ] = useState(true);
	const mdUp = useMediaQuery(
		(theme: Theme) => theme.breakpoints.up('md'),
		{ noSsr: true },
	);

	useEffect(
		() => {
			const params: { q?: string, starred?: string } = {};
			if (filters.q !== '') params.q = filters.q;
			if (filters.starred !== '') params.starred = filters.starred;
			setSearchParams(params, { replace: true });
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[filters],
	);

	useEffect(
		() => {
			if (!mdUp) {
				setSidebarOpen(false);
			} else {
				setSidebarOpen(true);
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[mdUp],
	);

	const handleChangeFilters = (filters: { q: string, starred: string }): void => {
		setFilters(filters);
	};

	const handleToggleSidebar = (): void => {
		if (isSidebarOpen) {
			setSidebarOpen(false);
		} else {
			setSidebarOpen(true);
		}
	};

	const handleCloseSidebar = () => {
		setSidebarOpen(false);
	};

	return (
		<>
			<Box
				sx={{
					alignItems: 'center',
					backgroundColor: 'background.paper',
					borderBottomColor: 'divider',
					borderBottomStyle: 'solid',
					borderBottomWidth: 1,
					display: 'flex',
					p: 2,
				}}
			>
				<Typography variant="h5">
					{/* TODO Lang */}
					Banque d'activités
				</Typography>
				<Box sx={{ flexGrow: 1 }} />
				<Button
					component={RouterLink}
					startIcon={<AddIcon />}
					to="/activities/add"
					variant="contained"
				>
					{/* TODO Lang */}
					Ajouter une activité
				</Button>
			</Box>
			<Box
				// TODO Put root component as main component?
				component="main"
				sx={{
					position: 'relative',
					height: '100%',
					width: '100%',
					overflow: 'hidden',
				}}
			>
				<Box
					ref={rootRef}
					sx={{
						display: 'flex',
						position: 'absolute',
						top: 0,
						right: 0,
						bottom: 0,
						left: 0,
					}}
				>
					<MailSidebar
						containerRef={rootRef}
						filters={filters}
						handleChangeFilters={handleChangeFilters}
						onClose={handleCloseSidebar}
						open={isSidebarOpen}
					/>
					<MailInner open={isSidebarOpen}>
						<MailList
							onToggleSidebar={handleToggleSidebar}
							filters={filters}
						/>
					</MailInner>
				</Box>
			</Box>
		</>
	);
}

export default ActivitiesPage;
