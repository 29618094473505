import { createSvgIcon } from '@mui/material/utils';

export const MenuAlt4 = createSvgIcon(
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 20 20"
		fill="currentColor"
	>
		<path
			fillRule="evenodd"
			d="M3 7a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 13a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
			clipRule="evenodd"
		/>
	</svg>,
	'MenuAlt4',
);
