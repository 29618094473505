// import { HistoryEdu as HistoryEduIcon } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import { FC, useEffect, useState } from 'react';
// import { Link as RouterLink} from 'react-router-dom';
import { AuthGuard } from '../../components/AuthGuard';
import { SuggestionsItem } from './Item';
import axios from '../../utils/axios';

interface Suggestion {
	commentCount: number;
	description: string;
	status: string;
	title: string;
	uid: string;
	vote: {
		choice: 'up' | 'down' | undefined;
		count: { up: number, down: number };
	}
}

const SuggestionsList: FC = (props) => {
	// FIXME Try AuthGuard before get suggestions
	const [ suggestions, setSuggestions ] = useState<Suggestion[] | null>(null);

	useEffect(
		() => {
			// TODO Use custom hook?
			axios.get('/suggestions')
				.then(function (response) {
					if (response.status === 200) {
						setSuggestions(response.data);
					} else {
						// Rollbar?
						console.log('Rollbar');
					}
				})
				.catch(function (error) {
					// Rollbar?
					console.log('Rollbar');
				});
			// TODO Find another solution
		}, [], // eslint-disable-line react-hooks/exhaustive-deps
	);

	if (suggestions === null) {
		return null;
	}

	return (
		// TODO Put guard before Dashboard displays
		<AuthGuard>
			<Box
				sx={{
					backgroundColor: 'background.paper',
					display: 'flex',
					flexDirection: 'column',
					height: '100%',
					overflow: 'hidden',
				}}
				{...props}
			>
				<Box
					sx={{
						alignItems: 'center',
						borderBottomColor: 'divider',
						borderBottomStyle: 'solid',
						borderBottomWidth: 1,
						display: 'flex',
						p: 2,
					}}
				>
					<Typography variant="h5">
						{/* TODO LANGUE */}
						Boîte à suggestions
					</Typography>
					<Box sx={{ flexGrow: 1 }} />
					{/* <Button
						component={RouterLink}
						color="secondary"
						startIcon={<HistoryEduIcon />}
						variant="contained"
						to="/suggestions/add"
					> */}
					{/* TODO LANGUE */}
					{/* Proposer une suggestion */}
					{/* </Button> */}
				</Box>
				{
					suggestions.length === 0
						? (
							<Box
								sx={{
									alignItems: 'center',
									backgroundColor: 'background.default',
									display: 'flex',
									flexGrow: 1,
									justifyContent: 'center',
									flexDirection: 'column',
									p: 2,
								}}
							>
								<Typography
									color="textSecondary"
									variant="h5"
								>
									{/* TODO LANGUE */}
									Il n'y a aucune suggestion active
								</Typography>
							</Box>
						)
						: (
							<>
								{suggestions.map((suggestion) => (
									<SuggestionsItem
										key={suggestion.uid}
										commentCount={suggestion.commentCount}
										description={suggestion.description}
										status={suggestion.status}
										title={suggestion.title}
										uid={suggestion.uid}
										vote={suggestion.vote}
									/>
								))}
							</>
						)
				}
			</Box>
		</AuthGuard>
	);
};

export default SuggestionsList;
