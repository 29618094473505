import { useEffect, useState } from 'react';
import type { FC } from 'react';
import {
	Box,
	IconButton,
	Typography,
} from '@mui/material';
import axios from '../../utils/axios';
import { Scrollbar } from '../../components/Scrollbar';
import { MenuAlt4 as MenuAlt4Icon } from '../../assets/icons/menu-alt-4';
import { ActivityItem } from './Item';
import { Activity } from './Types';

interface MailListProps {
	filters: { q: string, starred: string };
	onToggleSidebar?: () => void;
}

export const MailList: FC<MailListProps> = (props) => {
	const { filters, onToggleSidebar, ...other } = props;
	const [ activities, setActivities ] = useState([]);

	useEffect(
		() => {
			// TODO Use custom hook?
			axios.get('/activities/search', {
				params: filters,
			})
				.then(function (response) {
					if (response.status === 200) {
						setActivities(response.data);
						// if (!loaded) setLoaded(true);
					} else {
						// Rollbar?
						console.log('Rollbar');
					}
				})
				.catch(function (error) {
					// Rollbar?
					console.log('Rollbar');
				});
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[filters],
	);

	return (
		<Box
			sx={{
				backgroundColor: 'background.paper',
				display: 'flex',
				flexDirection: 'column',
				height: '100%',
				overflow: 'hidden',
			}}
			{...other}
		>
			<Box
				sx={{
					alignItems: 'center',
					borderBottomColor: 'divider',
					borderBottomStyle: 'solid',
					borderBottomWidth: 1,
					display: 'flex',
					p: 2,
				}}
			>
				<IconButton onClick={onToggleSidebar}>
					<MenuAlt4Icon fontSize="small" />
				</IconButton>
				{activities.length !== 0 &&
					<>
						<Box sx={{ flexGrow: 1 }} />
						<Typography
							color="textSecondary"
							sx={{
								display: {
									xs: 'none',
									md: 'block',
								},
								mx: 2,
								whiteSpace: 'nowrap',
							}}
							variant="body2"
						>
							{/* TODO Lang */}
							{ activities.length === 1 ? '1 activité trouvée' : activities.length + ' activités trouvées' }
						</Typography>
					</>
				}
			</Box>
			{
				activities.length === 0
					? (
						<Box
							sx={{
								alignItems: 'center',
								backgroundColor: 'background.default',
								display: 'flex',
								flexGrow: 1,
								justifyContent: 'center',
								flexDirection: 'column',
								p: 2,
							}}
						>
							<Typography
								color="textSecondary"
								variant="h5"
							>
								{/* TODO Lang */}
								Aucune activité trouvée
							</Typography>
						</Box>
					)
					: (
						<Scrollbar sx={{
							height: 'calc(100% - 69px)',
						}}>
							{activities.map((activity: Activity) => (
								<ActivityItem
									activity={activity}
									key={activity.uid}
								/>
							))}
						</Scrollbar>
					)
			}
		</Box>
	);
};
