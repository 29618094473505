import { useEffect, useState } from 'react';
import type { FC } from 'react';
import {
	Avatar,
	Badge,
	Box,
	IconButton,
	Table,
	TableBody,
	TableCell,
	TableRow,
	Tooltip,
} from '@mui/material';
import { ArrowCircleUp as ArrowCircleUpIcon, Calculate as CalculateIcon, ChatBubbleOutline as ChatBubbleOutlineIcon, Public as PublicIcon, Science as ScienceIcon, Square as SquareIcon } from '@mui/icons-material';
import { amber, blue, blueGrey, green, orange, purple, red } from '@mui/material/colors';
import { Scrollbar } from '../../components/Scrollbar';
import axios from '../../utils/axios';

interface CompetenciesListProps {
	activity: string;
}

export const CompetenciesList: FC<CompetenciesListProps> = (props) => {
	const {
		activity,
		...other
	} = props;
	const [ competencies, setCompetencies ] = useState<any[] | null>(null);

	const handleVote = (competency: string): void => {
		axios.put('/activities/' + activity + '/competencies/' + competency)
			.then(function (response) {
				if (response.status === 200) {
					const newList = competencies?.map((item) => {
						if (item.uid === competency) {
							const updatedItem = {
								...item,
								vote: response.data.vote,
								voteCount: response.data.voteCount,
							};
							return updatedItem;
						}
						return item;
					}) as any[];
					setCompetencies(newList);
				} else {
					// Rollbar?
					console.log('Rollbar');
				}
			})
			.catch(function (error) {
				// Rollbar?
				console.log('Rollbar');
			});
	};

	useEffect(
		() => {
			// TODO Use custom hook?
			axios.get('/activities/' + activity + '/competencies')
				.then(function (response) {
					if (response.status === 200) {
						setCompetencies(response.data);
					} else {
						// Rollbar?
						console.log('Rollbar');
					}
				})
				.catch(function (error) {
					// Rollbar?
					console.log('Rollbar');
				});
			// TODO Find another solution
		}, [], // eslint-disable-line react-hooks/exhaustive-deps
	);

	if (competencies === null) {
		return null;
	}

	return (
		<div {...other}>
			<Scrollbar>
				<Table sx={{ minWidth: 700 }}>
					<TableBody>
						{competencies.map((competency) => {
							return (
								<TableRow
									hover
									key={competency.uid}
								>
									<TableCell>
										<IconButton onClick={() => { handleVote(competency.uid); }}>
											<Badge badgeContent={competency.voteCount} color="info">
												<ArrowCircleUpIcon color={competency.vote ? 'secondary' : undefined} fontSize='large' />
											</Badge>
										</IconButton>
									</TableCell>
									<TableCell>
										<Tooltip title={competency.level}>
											{/* TODO Create component */}
											<Box
												sx={{
													alignItems: 'center',
													display: 'flex',
												}}
											>
												{/* Preschool */}
												{/* <SquareIcon sx={{ color: yellow[500], fontSize: 15 }} />
												<SquareIcon color="disabled" sx={{ fontSize: 15 }} />
												<SquareIcon color="disabled" sx={{ fontSize: 15 }} /> */}

												{/* Elementary */}
												<SquareIcon sx={{ color: amber[500], fontSize: 15 }} />
												<SquareIcon sx={{ color: amber[500], fontSize: 15 }} />
												<SquareIcon color="disabled" sx={{ fontSize: 15 }} />

												{/* Secondary */}
												{/* <SquareIcon sx={{ color: deepOrange[500], fontSize: 15 }} />
												<SquareIcon sx={{ color: deepOrange[500], fontSize: 15 }} />
												<SquareIcon sx={{ color: deepOrange[500], fontSize: 15 }} /> */}
											</Box>
										</Tooltip>
									</TableCell>
									<TableCell>
										<Box
											sx={{
												alignItems: 'center',
												display: 'flex',
											}}
										>
											{/* TODO Create component */}
											{ competency.subject === 'Français, langue d\'enseignement' &&
												<Tooltip title={competency.subject}>
													<Box sx={{ alignItems: 'center', cursor: 'default', display: 'flex' }}>
														<div style={{ color: orange[100], position: 'absolute', fontSize: 12, fontWeight: 'bold', marginLeft: 10, marginTop: -4 }}>FR</div>
														<ChatBubbleOutlineIcon fontSize="large" sx={{ color: orange[500] }} />
													</Box>
												</Tooltip>
											}
											{ competency.subject === 'Anglais, langue seconde' &&
												<Tooltip title={competency.subject}>
													<Box sx={{ alignItems: 'center', cursor: 'default', display: 'flex' }}>
														<div style={{ color: red[100], position: 'absolute', fontSize: 12, fontWeight: 'bold', marginLeft: 10, marginTop: -4 }}>EN</div>
														<ChatBubbleOutlineIcon fontSize="large" sx={{ color: red[500] }} />
													</Box>
												</Tooltip>
											}
											{ competency.subject === 'Mathématique' &&
												<Tooltip title={competency.subject}>
													<CalculateIcon fontSize="large" sx={{ color: blue[500] }} />
												</Tooltip>
											}
											{ competency.subject === 'Science et technologie' &&
												<Tooltip title={competency.subject}>
													<ScienceIcon fontSize="large" sx={{ color: purple[500] }} />
												</Tooltip>
											}
											{ competency.subject === 'Géographie, histoire et éducation à la citoyenneté' &&
												<Tooltip title={competency.subject}>
													<PublicIcon fontSize="large" sx={{ color: green[500] }} />
												</Tooltip>
											}
										</Box>
									</TableCell>
									<TableCell>
										{competency.name}
									</TableCell>
									<TableCell>
										<Box
											sx={{
												alignItems: 'center',
												display: 'flex',
											}}
										>
											<Avatar sx={{ bgcolor: blueGrey[600], mx: 0.5, width: 30, height: 30, visibility: (competency.cycles.includes(1)) ? 'visible' : 'hidden' }}>1</Avatar>
											<Avatar sx={{ bgcolor: blueGrey[700], mx: 0.5, width: 30, height: 30, visibility: (competency.cycles.includes(2)) ? 'visible' : 'hidden' }}>2</Avatar>
											<Avatar sx={{ bgcolor: blueGrey[800], mx: 0.5, width: 30, height: 30, visibility: (competency.cycles.includes(3)) ? 'visible' : 'hidden' }}>3</Avatar>
										</Box>
									</TableCell>
									{/* <TableCell>
										<Box
											sx={{
												alignItems: 'center',
												display: 'flex'
											}}
										>
											<Avatar
												src={customer.avatar}
												sx={{
													height: 42,
													width: 42
												}}
											>
												{getInitials(customer.name)}
											</Avatar>
											<Box sx={{ ml: 1 }}>
												<NextLink
													href="/dashboard/customers/1"
													passHref
												>
													<Link
														color="inherit"
														variant="subtitle2"
													>
														{customer.name}
													</Link>
												</NextLink>
												<Typography
													color="textSecondary"
													variant="body2"
												>
													{customer.email}
												</Typography>
											</Box>
										</Box>
									</TableCell>
									<TableCell>
										{customer.totalOrders}
									</TableCell> */}
								</TableRow>
							);
						})}
					</TableBody>
				</Table>
			</Scrollbar>
		</div>
	);
};
