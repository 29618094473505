import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { DashboardLayout } from './layouts/DashboardLayout';
import Account from './pages/Account';
import Changelog from './pages/Changelog';
import ConfirmEmailAddress from './pages/ConfirmEmailAddress';
import Dashboard from './pages/Dashboard';
import ForgotPassword from './pages/ForgotPassword';
import Home from './pages/Home';
import Login from './pages/Login';
import Register from './pages/Register';
import ResetPassword from './pages/ResetPassword';

import ActivitiesPage from './pages/activities/Page';
import ActivitiesAdd from './pages/activities/Add';
import ActivitiesView from './pages/activities/View';
import ApprenticesList from './pages/apprentices/List';
import ApprenticesAdd from './pages/apprentices/Add';
import ApprenticesEdit from './pages/apprentices/Edit';
import SuggestionsList from './pages/suggestions/List';
import SuggestionsView from './pages/suggestions/View';

import NotFound from './pages/errors/NotFound';

function Router(): JSX.Element {
	return (
		<BrowserRouter>
			<Routes>
				{/* Home routes */}
				<Route path="/" element={<Home />} />
				<Route path="changelog" element={<Changelog />} />

				{/* Auth routes */}
				<Route path="confirm-email-address/:uid/:token/:hash" element={<ConfirmEmailAddress />} />
				<Route path="forgot-password" element={<ForgotPassword />} />
				<Route path="login" element={<Login />} />
				<Route path="register" element={<Register />} />
				<Route path="reset-password/:uid/:token" element={<ResetPassword />} />

				{/* User pages */}
				<Route element={<DashboardLayout />}>
					<Route path="account" element={<Account />} />
					<Route path="dashboard" element={<Dashboard />} />

					<Route path="suggestions" element={<SuggestionsList />} />
					<Route path="suggestions/:uid" element={<SuggestionsView />} />

					<Route path="activities" element={<ActivitiesPage />} />
					<Route path="activities/add" element={<ActivitiesAdd />} />
					<Route path="activities/:uid" element={<ActivitiesView />} />

					<Route path="apprentices" element={<ApprenticesList />} />
					<Route path="apprentices/add" element={<ApprenticesAdd />} />
					<Route path="apprentices/:uid" element={<ApprenticesEdit />} />
				</Route>

				{/* Fallback */}
				<Route path="*" element={<NotFound />} />
			</Routes>
		</BrowserRouter>
	);
}

export default Router;
