import { useState } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { Box, Breadcrumbs, Button, Card, CardContent, Checkbox, Container, FormControlLabel, Grid, Link, TextField, Typography } from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import axios from '../../utils/axios';
import { AuthGuard } from '../../components/AuthGuard';

function ActivitiesAdd(): JSX.Element {
	const navigate = useNavigate();
	const [ stayOnPage, setStayOnPage ] = useState(false);
	const { t } = useTranslation();

	const formik = useFormik({
		initialValues: {
			name: '',
		},
		validationSchema: Yup.object({
			name: Yup
				.string()
				.max(255)	// TODO Lang
				.required(t('yup.name.required')),
		}),
		onSubmit: async (values, helpers): Promise<void> => {
			// TODO Use custom hook?
			axios.post('/activities', {
				name: values.name,
			})
				.then(function (response) {
					if (response.status === 200) {
						toast.success(response.data.message);
						if (stayOnPage === true) {
							formik.resetForm();
						} else {
							navigate('/activities');
						}
					} else {
						// Rollbar?
						console.log('Rollbar');
					}
				})
				.catch(function (error) {
					// Rollbar?
					console.log('Rollbar');
				});
		},
	});

	return (
		// TODO Put guard before Dashboard displays
		<AuthGuard>
			<Box
				component="main"
				sx={{
					flexGrow: 1,
					py: 8,
				}}
			>
				<Container maxWidth="md">
					<Box sx={{ mb: 3 }}>
						<Typography variant="h4">
							{ t('pages.activities.add.title') }
						</Typography>
						<Breadcrumbs
							separator="/"
							sx={{ mt: 1 }}
						>
							<Link
								component={RouterLink}
								to="/activities"
								variant="subtitle2"
							>
								{ t('pages.activities.page.title') }
							</Link>
							<Typography
								color="textSecondary"
								variant="subtitle2"
							>
								{ t('pages.activities.add.title') }
							</Typography>
						</Breadcrumbs>
					</Box>
					<form
						noValidate
						onSubmit={formik.handleSubmit}
					>
						<Card>
							<CardContent>
								<Grid
									container
									spacing={3}
								>
									<Grid
										item
										md={4}
										xs={12}
									>
										<Typography variant="h6">
											{ t('pages.activities.add.basicInformation') }
										</Typography>
									</Grid>
									<Grid
										item
										md={8}
										xs={12}
									>
										<TextField
											autoFocus
											error={Boolean(formik.touched.name && formik.errors.name)}
											fullWidth
											helperText={formik.touched.name && formik.errors.name}
											label={ t('pages.activities.add.name') }
											name="name"
											// TODO Best way to avoid error if page change?
											// onBlur={formik.handleBlur}
											onChange={formik.handleChange}
											required
											value={formik.values.name}
										/>
									</Grid>
								</Grid>
							</CardContent>
						</Card>
						<Box
							sx={{
								display: 'flex',
								flexWrap: 'wrap',
								justifyContent: 'flex-end',
								mx: -1,
								mt: 3,
							}}
						>
							<FormControlLabel control={<Checkbox checked={stayOnPage} onChange={(event: React.ChangeEvent<HTMLInputElement>) => { setStayOnPage(event.target.checked); }} />} label={ t('pages.activities.add.stayOnPage') } labelPlacement="start" sx={{ mr: 1 }}/>
						</Box>
						<Box
							sx={{
								display: 'flex',
								flexWrap: 'wrap',
								justifyContent: 'flex-end',
								mt: 1,
							}}
						>
							<Button
								component={RouterLink}
								sx={{ m: 1 }}
								to="/activities"
								variant="outlined"
							>
								{ t('pages.activities.add.back') }
							</Button>
							<Button
								sx={{ m: 1 }}
								type="submit"
								variant="contained"
							>
								{ t('pages.activities.add.submit') }
							</Button>
						</Box>
					</form>
				</Container>
			</Box>
		</AuthGuard>
	);
}

export default ActivitiesAdd;
