import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import { Toaster } from 'react-hot-toast';
import Router from './Router';
import { createTheme } from './theme';
import './i18n';
import { AuthProvider } from './contexts/AuthContext';

function App(): JSX.Element {
	return (
		<AuthProvider>
			<ThemeProvider
				theme={createTheme({
					// direction: 'ltr',
					responsiveFontSizes: true,
					mode: 'dark',
				})}
			>
				<CssBaseline />
				<Toaster position="top-center" />
				<Router />
			</ThemeProvider>
		</AuthProvider>
	);
}

export default App;
