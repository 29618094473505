import { blue, deepOrange, deepPurple, green, grey, pink } from '@mui/material/colors';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const resources = {
	fr: {
		translation: {
			components: {
				confirmDialog: {
					cancel: 'Annuler',
					confirm: 'Confirmer',
					content: 'Voulez-vous vraiment effectuer cette action?',
					title: 'Confirmation',
				},
			},
			// LAYOUTS
			layouts: {
				// Main Layout
				main: {
					navbar: {
						account: 'Mon compte',
						logout: 'Déconnexion',
					},
					sidebar: {
						activities: 'Banque d\'activités',
						apprentices: 'Mes apprentis',
						community: 'Communauté',
						dashboard: 'Tableau de bord',
						discussHere: 'Discutez-en ici 👇',
						general: 'Général',
						ideaToSuggest: 'Une idée à proposer?',
						suggestionBox: 'Boîte à suggestions',
					},
				},
			},
			// PAGES
			pages: {
				// Account page
				account: {
					title: 'Mon Compte',
					general: {
						title: 'Général',
						basicInformation: 'Informations de base',
						email: 'Courriel',
						emailNotConfirmed: 'Votre adresse courriel n\'est pas confirmée',
						firstName: 'Prénom',
						lastName: 'Nom de famille',
						save: 'Enregistrer',
						sendConfirmationEmail: 'Envoyer un courriel de confirmation',
					},
					password: {
						title: 'Mot de passe',
						editPassword: 'Modifier votre mot de passe',
						newPassword: 'Nouveau mot de passe',
						oldPassword: 'Ancien mot de passe',
						passwordConfirmation: 'Confirmation du mot de passe',
					},
				},
				// Activities pages
				activities: {
					add: {
						back: 'Retour',
						basicInformation: 'Informations de base',
						name: 'Nom',
						stayOnPage: 'Rester sur la page après l\'ajout',
						submit: 'Ajouter',
						title: 'Ajouter une activité',
					},
					page: {
						title: 'Banque d\'activités',
					},
					view: {
						competencies: 'Compétences',
					},
				},
				// Apprentices pages
				apprentices: {
					add: {
						back: 'Retour',
						basicInformation: 'Informations de base',
						birthDate: 'Date de naissance',
						firstName: 'Prénom',
						lastName: 'Nom de famille',
						submit: 'Ajouter',
						title: 'Ajouter un apprenti',
					},
					edit: {
						back: 'Retour',
						basicInformation: 'Informations de base',
						birthDate: 'Date de naissance',
						currentCycle: 'Cycle en cours',
						cycle: 'Cycle',
						cycleFrom: 'Début',
						cycleTo: 'Fin',
						firstName: 'Prénom',
						lastName: 'Nom de famille',
						submit: 'Enregistrer',
						title: 'Modifier un apprenti',
					},
					list: {
						add: 'Ajouter',
						addApprentice: 'Ajouter un apprenti',
						age: 'Âge',
						ageNotDefined: 'Non défini',
						cycle: 'Cycle',
						cycleNotDefined: 'Non défini',
						cycles: {
							elementary_1: 'Primaire — Cycle 1',
							elementary_2: 'Primaire — Cycle 2',
							elementary_3: 'Primaire — Cycle 3',
							secondary_1: 'Secondaire — Cycle 1',
							secondary_2: 'Secondaire — Cycle 2',
						},
						delete: 'Supprimer',
						deleteButton: 'Supprimer',
						deleteText: 'Voulez-vous vraiment supprimer l\'apprenti(e) <1>{{ name }}</1>?',
						edit: 'Modifier',
						name: 'Nom',
						noApprentice: 'Vous n\'avez aucun apprenti',
						title: 'Mes apprentis',
						years_one: '{{count}} an',
						years_other: '{{count}} ans',
					},
				},
				// Dashboard page
				dashboard: {
					apprenticeCycles: 'Cycles des apprentis',
					cycleDateFormat: 'D MMM',
					cycles: {
						elementary_1: '1<1>er</1> cycle du primaire',
						elementary_2: '2<1>e</1> cycle du primaire',
						elementary_3: '3<1>e</1> cycle du primaire',
						secondary_1: '1<1>er</1> cycle du secondaire',
						secondary_2: '2<1>e</1> cycle du secondaire',
					},
				},
				// Forgot password page
				forgotPassword: {
					title: 'Mot de passe oublié?',
					backButton: 'Retour à l\'accueil',
					email: 'Courriel',
					instructions: 'Veuillez entrer votre adresse courriel pour recevoir un lien de réinitialisation',
					sendLink: 'Envoyer un lien de réinitialisation',
				},
				// Login page
				login: {
					title: 'Connexion',
					createAccount: 'Créer un compte',
					email: 'Courriel',
					forgotPassword: 'Mot de passe oublié?',
					// TODO Constant for app name
					instructions: 'Connectez-vous sur Apprenti Parcours',
					login: 'Se connecter',
					password: 'Mot de passe',
				},
				// Register page
				register: {
					title: 'Inscription',
					email: 'Courriel',
					firstName: 'Prénom',
					havingAccount: 'Vous avez un compte?',
					// TODO Constant for app name
					instructions: 'Inscrivez-vous sur Apprenti Parcours',
					lastName: 'Nom de famille',
					password: 'Mot de passe',
					register: 'S\'inscrire',
					terms: {
						text: 'J\'ai lu et j\'accepte les',
						link: 'Termes et Conditions',
					},
				},
				// Forgot password page
				resetPassword: {
					title: 'Nouveau mot de passe',
					backButton: 'Retour à l\'accueil',
					instructions: 'Veuillez entrer un nouveau mot de passe',
					newPassword: 'Nouveau mot de passe',
					passwordConfirmation: 'Confirmation du mot de passe',
					resetPassword: 'Modifier mon mot de passe',
				},
				// Suggestions pages
				suggestions: {
					item: {
						aborted: {
							color: grey[600],
							text: 'ANNULÉ',
						},
						completed: {
							color: green[600],
							text: 'COMPLÉTÉ',
						},
						development: {
							color: deepPurple[500],
							text: 'EN DÉVELOPPEMENT',
						},
						new: {
							color: pink[500],
							text: 'NOUVEAU',
						},
						open: {
							color: blue[500],
							text: 'OUVERT',
						},
						review: {
							color: deepOrange[500],
							text: 'EN RÉVISION',
						},
					},
					view: {
						hasWritten: 'a écrit',
						send: 'Envoyer',
						writeComment: 'Écrivez un commentaire...',
					},
				},
			},
			// VALIDATION MESSAGE
			yup: {
				cycle: {
					from: {
						beforeTo: 'La date de début doit être antérieure à la date de fin',
						required: 'La date de début est requise',
					},
					to: {
						afterFrom: 'La date de fin doit être ultérieure à la date de début',
						required: 'La date de fin est requise',
					},
				},
				email: {
					required: 'L\'adresse courriel est requise',
					valid: 'L\'adresse courriel n\'est pas valide',
				},
				firstName: {
					required: 'Le prénom est requis',
				},
				lastName: {
					required: 'Le nom de famille est requis',
				},
				// TODO Change for "comment"?
				message: {
					required: 'Vous devez écrire un commentaire',
				},
				name: {
					required: 'Le nom est requis',
				},
				newPassword: {
					required: 'Un nouveau mot de passe est requis',
				},
				password: {
					required: 'Le mot de passe est requis',
				},
				passwordConfirmation: {
					match: 'Les mots de passe doivent être identiques',
					required: 'La confirmation du mot de passe est requise',
				},
				oldPassword: {
					required: 'L\'ancien mot de passe est requis',
					valid: 'L\'ancien mot de passe est incorrect',
				},
			},
		},
	},
};

i18n
	.use(initReactI18next)
	.init({
		resources,
		lng: 'fr',
		// fallbackLng: 'fr',
		// interpolation: {
		// 	escapeValue: false
		// }
	});
