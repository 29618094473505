import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import { Box, Button, Card, Checkbox, Container, Divider, FormHelperText, Link, TextField, Typography } from '@mui/material';
import { useFormik } from 'formik';
import toast from 'react-hot-toast';
import * as Yup from 'yup';
import { GuestGuard } from '../components/GuestGuard';
// import { Logo } from '../components/Logo';
import { CompactLogo } from '../components/CompactLogo';
import axios from '../utils/axios';

function Register(): JSX.Element {
	const { t } = useTranslation();

	const formik = useFormik({
		initialValues: {
			email: '',
			firstName: '',
			lastName: '',
			password: '',
			policy: false,
			submit: null,
		},
		validationSchema: Yup.object({
			email: Yup
				.string()
				.email(t('yup.email.valid'))
				.max(255)	// TODO Lang
				.required(t('yup.email.required')),
			firstName: Yup
				.string()
				.max(255)	// TODO Lang
				.required(t('yup.firstName.required')),
			lastName: Yup
				.string()
				.max(255)	// TODO Lang
				.required(t('yup.lastName.required')),
			password: Yup
				.string()
				.min(8)		// TODO Lang
				.max(255)	// TODO Lang
				.required(t('yup.password.required')),
			policy: Yup
				.boolean()
			// TODO Lang
				.oneOf([true], 'This field must be checked'),
		}),
		onSubmit: async (values, helpers): Promise<void> => {
			// TODO Use custom hook?
			axios.post('/register', {
				email: values.email,
				firstName: values.firstName,
				lastName: values.lastName,
				password: values.password,
			})
				.then(function (response) {
					if (response.status === 200) {
						toast.success(response.data.message);
						formik.resetForm();
					} else {
						// Rollbar?
						console.log('Rollbar');
					}
				})
				.catch(function (error) {
					// TODO Don't use code 400
					if (error.response.status === 400) {
						// TODO Email already used?
					} else {
						// Rollbar?
						console.log('Rollbar');
					}
				});
			// TODO Cleanup
			// try {
			// 	await register(values.email, values.password);

			// 	if (isMounted()) {
			// 		router.push('/authentication/verify-code');
			// 	}
			// } catch (err) {
			// 	console.error(err);

			// 	if (isMounted()) {
			// 		helpers.setStatus({ success: false });
			// 		helpers.setErrors({ submit: err.message });
			// 		helpers.setSubmitting(false);
			// 	}
			// }
		},
	});

	return (
		<GuestGuard>
			<Box
				component="main"
				sx={{
					backgroundColor: 'background.default',
					display: 'flex',
					flexDirection: 'column',
					minHeight: '100vh',
				}}
			>
				<Container
					maxWidth="sm"
					sx={{
						py: {
							xs: '60px',
						},
					}}
				>
					<Card
						elevation={16}
						sx={{ p: 4 }}
					>
						<Box
							sx={{
								alignItems: 'center',
								display: 'flex',
								flexDirection: 'column',
								justifyContent: 'center',
							}}
						>
							<Link component={RouterLink} to="/">
								{/*
								<Logo
									sx={{
										height: 40,
										width: 40,
									}}
								/>
								*/}
								<CompactLogo
									height={40}
									sx={{
										mb: '6.5px',
									}}
								/>
							</Link>
							<Typography variant="h4">
								{t('pages.register.title')}
							</Typography>
							<Typography
								color="textSecondary"
								sx={{ mt: 2 }}
								variant="body2"
							>
								{t('pages.register.instructions')}
							</Typography>
						</Box>
						<Box
							sx={{
								flexGrow: 1,
								mt: 3,
							}}
						>
							<form
								noValidate
								onSubmit={formik.handleSubmit}
							>
								<TextField
									error={Boolean(formik.touched.email && formik.errors.email)}
									fullWidth
									helperText={formik.touched.email && formik.errors.email}
									label={t('pages.register.email')}
									margin="normal"
									name="email"
									onBlur={formik.handleBlur}
									onChange={formik.handleChange}
									required
									type="email"
									value={formik.values.email}
								/>
								<TextField
									error={Boolean(formik.touched.firstName && formik.errors.firstName)}
									fullWidth
									helperText={formik.touched.firstName && formik.errors.firstName}
									label={t('pages.register.firstName')}
									margin="normal"
									name="firstName"
									onBlur={formik.handleBlur}
									onChange={formik.handleChange}
									required
									value={formik.values.firstName}
								/>
								<TextField
									error={Boolean(formik.touched.lastName && formik.errors.lastName)}
									fullWidth
									helperText={formik.touched.lastName && formik.errors.lastName}
									label={t('pages.register.lastName')}
									margin="normal"
									name="lastName"
									onBlur={formik.handleBlur}
									onChange={formik.handleChange}
									required
									value={formik.values.lastName}
								/>
								<TextField
									error={Boolean(formik.touched.password && formik.errors.password)}
									fullWidth
									helperText={formik.touched.password && formik.errors.password}
									label={t('pages.register.password')}
									margin="normal"
									name="password"
									onBlur={formik.handleBlur}
									onChange={formik.handleChange}
									required
									type="password"
									value={formik.values.password}
								/>
								<Box
									sx={{
										alignItems: 'center',
										display: 'flex',
										ml: -1,
										mt: 2,
									}}
								>
									<Checkbox
										checked={formik.values.policy}
										name="policy"
										onChange={formik.handleChange}
									/>
									<Typography
										color="textSecondary"
										variant="body2"
									>
										{t('pages.register.terms.text')}
										{' '}
										<Link
											component="a"
											href="#"
										>
											{t('pages.register.terms.link')}
										</Link> *
									</Typography>
								</Box>
								{Boolean(formik.touched.policy && formik.errors.policy) && (
									<FormHelperText error>
										{formik.errors.policy}
									</FormHelperText>
								)}
								{formik.errors.submit && (
									<Box sx={{ mt: 3 }}>
										<FormHelperText error>
											{formik.errors.submit}
										</FormHelperText>
									</Box>
								)}
								<Box sx={{ mt: 2 }}>
									<Button
										disabled={formik.isSubmitting}
										fullWidth
										size="large"
										type="submit"
										variant="contained"
									>
										{t('pages.register.register')}
									</Button>
								</Box>
							</form>
						</Box>
						<Divider sx={{ my: 3 }} />
						<Button
							component={RouterLink}
							fullWidth
							size="large"
							to="/login"
							variant="outlined"
						>
							{t('pages.register.havingAccount')}
						</Button>
					</Card>
				</Container>
			</Box>
		</GuestGuard>
	);
}

export default Register;
