import moment from 'moment';
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { Collections as CollectionsIcon, Delete as DeleteIcon, DoDisturb as DoDisturbIcon } from '@mui/icons-material';
import { Avatar, Box, Breadcrumbs, Button, ButtonBase, Card, CardContent, Container, Grid, Link, TextField, Typography } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import axios from '../../utils/axios';
import { AuthGuard } from '../../components/AuthGuard';

// TODO Declare Avatar an use alias for MUI component instead?
type AvatarData = {
	color: string,
	data: string,
	uid: string,
};

function ApprenticesAdd(): JSX.Element | null {
	const navigate = useNavigate();
	const [ avatars, setAvatars ] = useState<AvatarData[] | undefined>(undefined);
	const [ selectedAvatar, setSelectedAvatar ] = useState<{file?: File, uid?: string} | undefined>(undefined);
	const { t } = useTranslation();
	const avatarFileInput = useRef<HTMLInputElement>(null);


	const formik = useFormik({
		initialValues: {
			birthDate: null,
			firstName: '',
			lastName: '',
		},
		validationSchema: Yup.object({
			// BUG Validate birthdate
			firstName: Yup
				.string()
				.max(255)	// TODO Lang
				.required(t('yup.firstName.required')),
			lastName: Yup
				.string()
				.max(255)	// TODO Lang
				.required(t('yup.lastName.required')),
		}),
		onSubmit: async (values, helpers): Promise<void> => {
			let data;
			if (selectedAvatar?.file) {
				data = new FormData();
				data.append('avatar', selectedAvatar.file);
				if (values.birthDate !== null) {
					data.append('birthDate', moment(values.birthDate).format('YYYY-MM-DD'));
				}
				data.append('firstName', values.firstName);
				data.append('lastName', values.lastName);
			} else {
				data = {
					avatar: selectedAvatar?.uid,
					birthDate: (values.birthDate !== null) ? moment(values.birthDate).format('YYYY-MM-DD') : undefined,
					firstName: values.firstName,
					lastName: values.lastName,
				};
			}
			// TODO Use custom hook?
			axios.post('/apprentices', data)
				.then(function (response) {
					if (response.status === 200) {
						toast.success(response.data.message);
						navigate('/apprentices');
					} else {
						// Rollbar?
						console.log('Rollbar');
					}
				})
				.catch(function (error) {
					// Rollbar?
					console.log('Rollbar');
				});
		},
	});

	useEffect(
		() => {
			// TODO Use custom hook?
			axios.get('/apprentices/avatars')
				.then(function (response) {
					if (response.status === 200) {
						setAvatars(response.data);
					} else {
						// Rollbar?
						console.log('Rollbar');
					}
				})
				.catch(function (error) {
					// Rollbar?
					console.log('Rollbar');
				});
			// TODO Find another solution
		}, [], // eslint-disable-line react-hooks/exhaustive-deps
	);

	const handleFileInput = (event: ChangeEvent<HTMLInputElement>) => {
		if (event.target.files === null) {
			// TODO Rollbar
			return;
		}
		const file = event.target.files[0] as File;
		if (['image/gif', 'image/jpeg', 'image/png'].includes(file.type) !== true) {
			if (avatarFileInput.current !== null) avatarFileInput.current.value = '';
			// TODO Lang
			toast.error('Seuls les fichiers au format GIF, JPG et PNG sont acceptés.');
			return;
		} else if (file.size > 5 * 1024 * 1024) {
			if (avatarFileInput.current !== null) avatarFileInput.current.value = '';
			// TODO Lang
			toast.error('La taille du fichier ne doit pas dépasser 5 Mo.');
			return;
		}
		setSelectedAvatar({ file: file });
	};

	const handleCancelFileInput = () => {
		if (avatarFileInput.current !== null) avatarFileInput.current.value = '';
	};

	if (avatars === undefined) {
		return null;
	}

	return (
		// TODO Put guard before Dashboard displays
		<AuthGuard>
			<Box
				component="main"
				sx={{
					flexGrow: 1,
					py: 8,
				}}
			>
				<Container maxWidth="md">
					<Box sx={{ mb: 3 }}>
						<Typography variant="h4">
							{ t('pages.apprentices.add.title') }
						</Typography>
						<Breadcrumbs
							separator="/"
							sx={{ mt: 1 }}
						>
							<Link
								component={RouterLink}
								to="/apprentices"
								variant="subtitle2"
							>
								{ t('pages.apprentices.list.title') }
							</Link>
							<Typography
								color="textSecondary"
								variant="subtitle2"
							>
								{ t('pages.apprentices.add.title') }
							</Typography>
						</Breadcrumbs>
					</Box>
					<form
						noValidate
						onSubmit={formik.handleSubmit}
					>
						<Card>
							<CardContent>
								<Grid
									container
									spacing={3}
								>
									<Grid
										item
										md={4}
										xs={12}
									>
										<Typography variant="h6">
											{ t('pages.apprentices.add.basicInformation') }
										</Typography>
									</Grid>
									<Grid
										item
										md={8}
										xs={12}
									>
										<TextField
											error={Boolean(formik.touched.firstName && formik.errors.firstName)}
											fullWidth
											helperText={formik.touched.firstName && formik.errors.firstName}
											label={ t('pages.apprentices.add.firstName') }
											name="firstName"
											onBlur={formik.handleBlur}
											onChange={formik.handleChange}
											required
											value={formik.values.firstName}
										/>
										<TextField
											error={Boolean(formik.touched.lastName && formik.errors.lastName)}
											fullWidth
											helperText={formik.touched.lastName && formik.errors.lastName}
											label={ t('pages.apprentices.add.lastName') }
											name="lastName"
											onBlur={formik.handleBlur}
											sx={{ mt: 2 }}
											onChange={formik.handleChange}
											required
											value={formik.values.lastName}
										/>
										<LocalizationProvider dateAdapter={AdapterMoment}>
											<DatePicker
												inputFormat="YYYY-MM-DD"
												label={ t('pages.apprentices.add.birthDate') }
												value={formik.values.birthDate}
												onChange={value => formik.setFieldValue('birthDate', value)}
												renderInput={(params) => <TextField sx={{ mt: 2 }} {...params} />}
											/>
										</LocalizationProvider>
									</Grid>
								</Grid>
							</CardContent>
						</Card>
						<Card sx={{ mt: 3 }}>
							<CardContent>
								<Grid
									container
									spacing={3}
								>
									<Grid
										item
										md={4}
										xs={12}
									>
										<Typography variant="h6">
											{/* TODO Lang */}
											{ t('Avatar') }
										</Typography>
									</Grid>
									<Grid
										item
										md={8}
										xs={12}
									>
										<Grid container spacing={2}>
											<Grid item>
												<Avatar
													component={ButtonBase}
													onClick={ () => { setSelectedAvatar(undefined); handleCancelFileInput(); } }
													sx={{
														bgcolor: (selectedAvatar === undefined) ? 'neutral.800' : '#444',
														border: (selectedAvatar === undefined) ? '3px solid' : 'initial',
														borderColor: (selectedAvatar === undefined) ? 'text.primary' : 'initial',
														width: 85,
														height: 85,
														'&:hover': {
															backgroundColor: 'neutral.800',
														},
													}}
												>
													<DoDisturbIcon sx={{ fontSize: '40px' }} />
												</Avatar>
											</Grid>
											<Grid item>
												<Avatar
													component={ButtonBase}
													onClick={() => {
														if (selectedAvatar?.file === undefined) {
															if (avatarFileInput.current !== null) avatarFileInput.current.click();
														} else {
															setSelectedAvatar(undefined);
															handleCancelFileInput();
														}
													}}
													sx={{
														bgcolor: '#444',
														width: 85,
														height: 85,
														'&:hover': {
															backgroundColor: 'neutral.800',
														},
													}}
												>
													{(selectedAvatar?.file === undefined) && <CollectionsIcon sx={{ fontSize: '40px' }} />}

													{(selectedAvatar?.file !== undefined) &&
														<>
															<Avatar
																// BUG Manage animated GIF files
																src={URL.createObjectURL(selectedAvatar.file)}
																sx={{
																	border: '3px solid',
																	borderColor: 'text.primary',
																	height: 85,
																	position: 'absolute',
																	width: 85,
																}}
															/>
															<Avatar
																sx={{
																	bgcolor: 'rgba(0, 0, 0, 0.6)',
																	border: '3px solid',
																	borderColor: 'text.primary',
																	height: 85,
																	position: 'absolute',
																	opacity: 0,
																	width: 85,
																	'&:hover': {
																		opacity: 1,
																	},
																}}
															>
																<DeleteIcon sx={{ fontSize: '40px' }} />
															</Avatar>
														</>
													}
												</Avatar>
											</Grid>
											{ (avatars !== undefined) && avatars.map(function(avatar: AvatarData) {
												return (
													<Grid key={avatar.uid} item>
														<Avatar
															component={ButtonBase}
															onClick={ () => { setSelectedAvatar({ uid: avatar.uid }); handleCancelFileInput(); } }
															src={avatar.data}
															sx={{
																bgcolor: (selectedAvatar?.uid === avatar.uid) ? avatar.color : '#444',
																border: (selectedAvatar?.uid === avatar.uid) ? '3px solid' : 'initial',
																padding: (selectedAvatar?.uid === avatar.uid) ? 'initial' : '3px',
																width: 85,
																height: 85,

																'&:hover': {
																	backgroundColor: avatar.color,
																} }}
														/>
													</Grid>
												);
											})}
											<Box sx={{ display: 'none' }}>
												<input
													type="file"
													ref={avatarFileInput}
													onChange={handleFileInput}
												/>
											</Box>
										</Grid>
									</Grid>
								</Grid>
							</CardContent>
						</Card>
						<Box
							sx={{
								display: 'flex',
								flexWrap: 'wrap',
								justifyContent: 'flex-end',
								mx: -1,
								mb: -1,
								mt: 3,
							}}
						>
							<Button
								component={RouterLink}
								sx={{ m: 1 }}
								to="/apprentices"
								variant="outlined"
							>
								{ t('pages.apprentices.add.back') }
							</Button>
							<Button
								sx={{ m: 1 }}
								type="submit"
								variant="contained"
							>
								{ t('pages.apprentices.add.submit') }
							</Button>
						</Box>
					</form>
				</Container>
			</Box>
		</AuthGuard>
	);
}

export default ApprenticesAdd;
