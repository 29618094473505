import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import {
	Box,
	Button,
	Card,
	CardContent,
	Grid,
	TextField,
	Typography,
} from '@mui/material';
import { useFormik } from 'formik';
import toast from 'react-hot-toast';
import * as Yup from 'yup';
import axios from '../../utils/axios';

export const AccountPasswordSettings: FC = (props) => {
	const { t } = useTranslation();

	const formik = useFormik({
		initialValues: {
			oldPassword: '',
			password: '',
			passwordConfirmation: '',
		},
		validationSchema: Yup.object({
			oldPassword: Yup
				.string()
				.required(t('yup.oldPassword.required')),
			password: Yup
				.string()
				.min(8)		// TODO Lang
				.max(255)	// TODO Lang
				.required(t('yup.newPassword.required')),
			passwordConfirmation: Yup
				.string()
				.required(t('yup.passwordConfirmation.required'))
				.oneOf([Yup.ref('password')], t('yup.passwordConfirmation.match')),
		}),
		onSubmit: async (values, helpers): Promise<void> => {
			// TODO Use custom hook?
			axios.put('/profile/password', {
				oldPassword: values.oldPassword,
				password: values.password,
				passwordConfirmation: values.passwordConfirmation,
			})
				.then((response) => {
					if (response.status === 200) {
						formik.resetForm(); // TODO Needed?
						toast.success(response.data.message);
					} else {
						// Rollbar?
						console.log('Rollbar');
					}
				})
				.catch((error) => {
					// TODO Don't use code 400
					// Use 401 instead, then the old password is incorrect
					if (error.response.status === 400) {
						helpers.setFieldError('oldPassword', t('yup.oldPassword.valid'));

						// const errors = error.response.data.errors;
						// TODO Old password incorrect
						// TODO Get message from backend?
						// helpers.setErrors(errors);
						// helpers.setSubmitting(false);
					} else {
						// Rollbar?
						console.log('Rollbar');
					}
				});
		},
	});

	return (
		<Box
			{...props}
		>
			<Card>
				<CardContent>
					<Grid
						container
						spacing={3}
					>
						<Grid
							item
							md={4}
							xs={12}
						>
							<Typography variant="h6">
								{t('pages.account.password.editPassword')}
							</Typography>
						</Grid>
						<Grid
							item
							md={8}
							xs={12}
						>
							<form
								noValidate
								onSubmit={formik.handleSubmit}
							>
								<Box
									sx={{
										alignItems: 'center',
										display: 'flex',
									}}
								>
									<TextField
										error={Boolean(formik.touched.oldPassword && formik.errors.oldPassword)}
										helperText={formik.touched.oldPassword && formik.errors.oldPassword}
										label={ t('pages.account.password.oldPassword') }
										name="oldPassword"
										onBlur={formik.handleBlur}
										onChange={formik.handleChange}
										required
										size="small"
										sx={{
											flexGrow: 1,
											mr: 3,
										}}
										type="password"
										value={formik.values.oldPassword}
									/>
								</Box>
								<Box
									sx={{
										alignItems: 'center',
										display: 'flex',
										mt: 3,
									}}
								>
									<TextField
										error={Boolean(formik.touched.password && formik.errors.password)}
										helperText={formik.touched.password && formik.errors.password}
										label={ t('pages.account.password.newPassword') }
										name="password"
										onBlur={formik.handleBlur}
										onChange={formik.handleChange}
										required
										size="small"
										sx={{
											flexGrow: 1,
											mr: 3,
										}}
										type="password"
										value={formik.values.password}
									/>
								</Box>
								<Box
									sx={{
										alignItems: 'center',
										display: 'flex',
										mt: 3,
									}}
								>
									<TextField
										error={Boolean(formik.touched.passwordConfirmation && formik.errors.passwordConfirmation)}
										helperText={formik.touched.passwordConfirmation && formik.errors.passwordConfirmation}
										label={ t('pages.account.password.passwordConfirmation') }
										name="passwordConfirmation"
										onBlur={formik.handleBlur}
										onChange={formik.handleChange}
										required
										size="small"
										sx={{
											flexGrow: 1,
											mr: 3,
										}}
										type="password"
										value={formik.values.passwordConfirmation}
									/>
								</Box>
								<Box
									sx={{
										justifyContent: 'flex-end',
										display: 'flex',
										mt: 3,
									}}
								>
									<Button
										sx={{
											mr: 3,
										}}
										variant="contained"
										type="submit"
									>
										{ t('pages.account.general.save') }
									</Button>
								</Box>
							</form>
						</Grid>
					</Grid>
				</CardContent>
			</Card>
		</Box>
	);
};
