import { useEffect, useState } from 'react';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import {
	Alert,
	Box,
	Button,
	Card,
	CardContent,
	Grid,
	TextField,
	Typography,
} from '@mui/material';
import { useFormik } from 'formik';
import toast from 'react-hot-toast';
import * as Yup from 'yup';
import axios from '../../utils/axios';
import { useAuth } from '../../hooks/useAuth';

export const AccountGeneralSettings: FC = (props) => {
	const { updateUser } = useAuth();
	const [ loaded, setLoaded ] = useState(false);
	const [ emailConfirmed, setEmailConfirmed ] = useState();
	const { t } = useTranslation();

	// To get the user from the authContext, you can use
	// `const { user } = useAuth();`
	const formik = useFormik({
		initialValues: {
			email: '',
			firstName: '',
			lastName: '',
		},
		validationSchema: Yup.object({
			email: Yup
				.string()
				.email(t('yup.email.valid'))
				.max(255)	// TODO Lang
				.required(t('yup.email.required')),
			firstName: Yup
				.string()
				.max(255)	// TODO Lang
				.required(t('yup.firstName.required')),
			lastName: Yup
				.string()
				.max(255)	// TODO Lang
				.required(t('yup.lastName.required')),
		}),
		onSubmit: async (values, helpers): Promise<void> => {
			// TODO Use custom hook?
			axios.put('/profile/general', {
				email: values.email,
				firstName: values.firstName,
				lastName: values.lastName,
			})
				.then((response) => {
					if (response.status === 200) {
						// TODO setFieldValue?
						formik.values.email = response.data.data.email;
						formik.values.firstName = response.data.data.firstName;
						formik.values.lastName = response.data.data.lastName;
						setEmailConfirmed(response.data.data.emailConfirmedAt);

						updateUser({firstName: response.data.data.firstName, lastName: response.data.data.lastName});

						toast.success(response.data.message);
					} else {
						// Rollbar?
						console.log('Rollbar');
					}
				})
				.catch((error) => {
					// TODO Don't use code 400
					if (error.response.status === 400) {
						// TODO Email already used?
					} else {
						// Rollbar?
						console.log('Rollbar');
					}
				});
		},
	});

	useEffect(
		() => {
			// TODO Use custom hook?
			axios.get('/profile')
				.then(function (response) {
					if (response.status === 200) {
						// TODO setFieldValue?
						formik.values.email = response.data.general.email;
						formik.values.firstName = response.data.general.firstName;
						formik.values.lastName = response.data.general.lastName;
						setEmailConfirmed(response.data.general.emailConfirmedAt);
						updateUser({firstName: response.data.general.firstName, lastName: response.data.general.lastName});
						setLoaded(true);
					} else {
						// Rollbar?
						console.log('Rollbar');
					}
				})
				.catch(function (error) {
					// Rollbar?
					console.log('Rollbar');
				});
			// TODO Find another solution
		}, [], // eslint-disable-line react-hooks/exhaustive-deps
	);

	if (!loaded) {
		return null;
	}

	const sendConfirmationEmail = function () {
		// TODO Use custom hook? Use constant?
		axios.put('/profile/send-email-confirmation-link')
			.then(function (response) {
				if (response.status === 200) {
					toast.success(response.data.message);
				} else {
					// Rollbar?
					console.log('Rollbar');
				}
			})
			.catch(function (error) {
				// Rollbar?
				console.log('Rollbar');
			});
	};

	return (
		<Box
			{...props}
		>
			<Card>
				<CardContent>
					<Grid
						container
						spacing={3}
					>
						<Grid
							item
							md={4}
							xs={12}
						>
							<Typography variant="h6">
								{t('pages.account.general.basicInformation')}
							</Typography>
						</Grid>
						<Grid
							item
							md={8}
							xs={12}
						>
							<form
								noValidate
								onSubmit={formik.handleSubmit}
							>
								<Box
									sx={{
										alignItems: 'center',
										display: 'flex',
									}}
								>
									<TextField
										error={Boolean(formik.touched.firstName && formik.errors.firstName)}
										helperText={formik.touched.firstName && formik.errors.firstName}
										label={ t('pages.account.general.firstName') }
										name="firstName"
										onBlur={formik.handleBlur}
										onChange={formik.handleChange}
										required
										size="small"
										sx={{
											flexGrow: 1,
											mr: 3,
										}}
										value={formik.values.firstName}
									/>
								</Box>
								<Box
									sx={{
										alignItems: 'center',
										display: 'flex',
										mt: 3,
									}}
								>
									<TextField
										error={Boolean(formik.touched.lastName && formik.errors.lastName)}
										helperText={formik.touched.lastName && formik.errors.lastName}
										label={ t('pages.account.general.lastName') }
										name="lastName"
										onBlur={formik.handleBlur}
										onChange={formik.handleChange}
										required
										size="small"
										sx={{
											flexGrow: 1,
											mr: 3,
										}}
										value={formik.values.lastName}
									/>
								</Box>
								<Box
									sx={{
										alignItems: 'center',
										display: 'flex',
										mt: 3,
									}}
								>
									<TextField
										error={Boolean(formik.touched.email && formik.errors.email)}
										helperText={formik.touched.email && formik.errors.email}
										label={ t('pages.account.general.email') }
										name="email"
										onBlur={formik.handleBlur}
										onChange={formik.handleChange}
										required
										size="small"
										sx={{
											flexGrow: 1,
											mr: 3,
										}}
										type="email"
										value={formik.values.email}
									/>
								</Box>
								{!emailConfirmed &&
									<Box
										sx={{
											alignItems: 'center',
											display: 'flex',
											width: '100%',
											mt: 3,
										}}
									>
										<Alert
											sx={{
												flexGrow: 1,
												mr: 3,
											}}
											severity="warning"
											action={
												<Button color="inherit" size="small" onClick={sendConfirmationEmail}>
													{ t('pages.account.general.sendConfirmationEmail').toUpperCase() }
												</Button>
											}
										>
											{ t('pages.account.general.emailNotConfirmed') }
										</Alert>
									</Box>
								}
								<Box
									sx={{
										justifyContent: 'flex-end',
										display: 'flex',
										mt: 3,
									}}
								>
									<Button
										sx={{
											mr: 3,
										}}
										variant="contained"
										type="submit"
									>
										{ t('pages.account.general.save') }
									</Button>
								</Box>
							</form>
						</Grid>
					</Grid>
				</CardContent>
			</Card>
		</Box>
	);
};
