import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
// TODO Use SVG
import imgCompactLogo from '../assets/images/logos/compact-logo.png';

interface CompactLogoProps {
	height?: number;
}

export const CompactLogo = styled((props: CompactLogoProps) => {
	const { height, ...other } = props;
	const width = (height ?? 73) / 73 * 67;

	return (
		<Box
			{...other}
			component="div"
			sx={{
				backgroundImage: `url(${imgCompactLogo})`,
				backgroundPosition: 'center center',
				backgroundRepeat: 'no-repeat',
				backgroundSize: 'cover',
				display: 'inline-block',
				height: height,
				width: width,
			}}
		/>
	);
})``;

// TODO Why is this used?
CompactLogo.defaultProps = {
	height: 73,
};

CompactLogo.propTypes = {
	height: PropTypes.number,
};
