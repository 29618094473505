import { useState } from 'react';
import type { ChangeEvent } from 'react';
import { Box, Container, Divider, Tab, Tabs, Typography } from '@mui/material';
import { AccountGeneralSettings } from './account/AccountGeneralSettings';
import { AccountPasswordSettings } from './account/AccountPasswordSettings';
import { AuthGuard } from '../components/AuthGuard';
import { useTranslation } from 'react-i18next';

function Account(): JSX.Element {
	const [currentTab, setCurrentTab] = useState<string>('general');
	const { t } = useTranslation();
	const handleTabsChange = (event: ChangeEvent<{}>, value: string): void => { // eslint-disable-line @typescript-eslint/ban-types
		setCurrentTab(value);
	};

	const tabs = [
		{ label: t('pages.account.general.title'), value: 'general' },
		{ label: t('pages.account.password.title'), value: 'password' },
	];

	return (
		// TODO Put guard before Dashboard displays
		<AuthGuard>
			<Box
				component="main"
				sx={{
					flexGrow: 1,
					py: 8,
				}}
			>
				<Container maxWidth="md">
					<Typography variant="h4">
						{ t('pages.account.title') }
					</Typography>
					<Tabs
						indicatorColor="primary"
						onChange={handleTabsChange}
						scrollButtons="auto"
						textColor="primary"
						value={currentTab}
						variant="scrollable"
						sx={{ mt: 3 }}
					>
						{tabs.map((tab) => (
							<Tab
								key={tab.value}
								label={tab.label}
								value={tab.value}
							/>
						))}
					</Tabs>
					<Divider sx={{ mb: 3 }} />
					{currentTab === 'general' && <AccountGeneralSettings />}
					{currentTab === 'password' && <AccountPasswordSettings />}
				</Container>
			</Box>
		</AuthGuard>
	);
}

export default Account;
