import type { FC } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import toast from 'react-hot-toast';
import {
	Avatar,
	Box,
	Divider,
	ListItemIcon,
	ListItemText,
	MenuItem,
	Popover,
	Typography,
} from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';
import { UserCircle as UserCircleIcon } from '../assets/icons/user-circle';
import axios from '../utils/axios';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../hooks/useAuth';

interface AccountPopoverProps {
	anchorEl: null | Element;
	onClose?: () => void;
	open: boolean;
}

export const AccountPopover: FC<AccountPopoverProps> = (props) => {
	const { anchorEl, onClose, open, ...other } = props;
	const navigate = useNavigate();
	const { t } = useTranslation();
	const { user, updateUser } = useAuth();

	// const router = useRouter();
	// const { logout } = useAuth();
	// To get the user from the authContext, you can use
	// `const { user } = useAuth();`
	const info = {
		avatar: '/static/mock-images/avatars/avatar-anika_visser.png',
		name: (user !== null) ? user.firstName + ' ' + user.lastName : '',
	};

	const handleLogout = async (): Promise<void> => {
		onClose?.();
		axios.post('/logout')
			.then(function (response) {
				if (response.status === 204) {
					updateUser(null);
					navigate('/login');
				} else {
					toast.error('Unable to logout.');

					// Rollbar?
					console.log('Rollbar');
				}
			})
			.catch(function (error) {
				toast.error('Unable to logout.');

				// Rollbar?
				console.log('Rollbar');
			});
	};

	return (
		<Popover
			anchorEl={anchorEl}
			anchorOrigin={{
				horizontal: 'center',
				vertical: 'bottom',
			}}
			keepMounted
			onClose={onClose}
			open={open}
			PaperProps={{ sx: { width: 300 } }}
			transitionDuration={0}
			{...other}
		>
			<Box
				sx={{
					alignItems: 'center',
					p: 2,
					display: 'flex',
				}}
			>
				<Avatar
					src={info.avatar}
					sx={{
						height: 40,
						width: 40,
					}}
				>
					<UserCircleIcon fontSize="small" />
				</Avatar>
				<Box
					sx={{
						ml: 1,
					}}
				>
					<Typography variant="body1">
						{info.name}
					</Typography>
				</Box>
			</Box>
			<Divider />
			<Box sx={{ my: 1 }}>
				<MenuItem
					component={RouterLink}
					onClick={onClose}
					to="/account"
				>
					<ListItemIcon>
						<UserCircleIcon fontSize="small" />
					</ListItemIcon>
					<ListItemText
						primary={(
							<Typography variant="body1">
								{ t('layouts.main.navbar.account') }
							</Typography>
						)}
					/>
				</MenuItem>
				<Divider />
				<MenuItem onClick={handleLogout}>
					<ListItemIcon>
						<LogoutIcon fontSize="small" />
					</ListItemIcon>
					<ListItemText
						primary={(
							<Typography variant="body1">
								{ t('layouts.main.navbar.logout') }
							</Typography>
						)}
					/>
				</MenuItem>
			</Box>
		</Popover>
	);
};

AccountPopover.propTypes = {
	anchorEl: PropTypes.any,
	onClose: PropTypes.func,
	open: PropTypes.bool.isRequired,
};
