import type { FC, ReactNode } from 'react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import axios from '../utils/axios';

interface GuestGuardProps {
	children: ReactNode;
}

export const GuestGuard: FC<GuestGuardProps> = (props) => {
	const { children } = props;
	const navigate = useNavigate();
	const [checked, setChecked] = useState(false);

	useEffect(
		() => {
			// TODO Use custom hook?
			axios.get('/status')
				.then(function (response) {
					if (response.status === 200) {
						if (response.data.authenticated === false) {
							setChecked(true);
						} else if (response.data.authenticated === true) {
							navigate('/dashboard');
						} else {
							// Rollbar?
							console.log('Rollbar');
						}
					} else {
						// Rollbar?
						console.log('Rollbar');
					}
				})
				.catch(function (error) {
					// Rollbar?
					console.log('Rollbar');
				});
		}, [], // eslint-disable-line react-hooks/exhaustive-deps
	);

	if (!checked) {
		return null;
	}

	// If got here, it means that the redirect did not occur, and that tells us that the user is
	// not authenticated / authorized.

	return <>{children}</>;
};

GuestGuard.propTypes = {
	children: PropTypes.node,
};
