import { ChangeEvent, useEffect, useState } from 'react';
import { Link as RouterLink, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
	Box,
	Card,
	Container,
	Divider,
	IconButton,
	Tab,
	Tabs,
	Typography,
} from '@mui/material';
import { ArrowLeft as ArrowLeftIcon } from '../../assets/icons/arrow-left';
import axios from '../../utils/axios';
import { CompetenciesList } from './ViewCompetencies';

interface Activity {
	name: string;
}

function ActivitiesView(): JSX.Element | null {
	const uid = useParams().uid as string;
	const { t } = useTranslation();
	const [ activity, setActivity ] = useState<Activity | null>(null);
	const [currentTab, setCurrentTab] = useState<string>('competencies');
	const handleTabsChange = (event: ChangeEvent<{}>, value: string): void => { // eslint-disable-line @typescript-eslint/ban-types
		setCurrentTab(value);
	};

	const tabs = [
		{ label: t('pages.activities.view.competencies'), value: 'competencies' },
		// { label: t('pages.activities.view.competencies'), value: 'resources' },
		// { label: t('pages.activities.view.competencies'), value: 'related-activities' },
	];

	useEffect(
		() => {
			// TODO Use custom hook?
			axios.get('/activities/' + uid)
				.then(function (response) {
					if (response.status === 200) {
						setActivity(response.data);
					} else {
						// Rollbar?
						console.log('Rollbar');
					}
				})
				.catch(function (error) {
					// Rollbar?
					console.log('Rollbar');
				});
			// TODO Find another solution
		}, [], // eslint-disable-line react-hooks/exhaustive-deps
	);

	if (activity === null) {
		return null;
	}

	return (
		<Box
			sx={{
				backgroundColor: 'background.default',
				display: 'flex',
				flexDirection: 'column',
				flexGrow: 1,
				height: '100%',
				overflowY: 'auto',
			}}
		>
			<Box
				sx={{
					alignItems: 'center',
					backgroundColor: 'background.paper',
					display: 'flex',
					flexShrink: 0,
					p: 2,
				}}
			>
				<IconButton
					component={RouterLink}
					to={'/activities'}
				>
					<ArrowLeftIcon fontSize="small" />
				</IconButton>
			</Box>
			<Divider />
			<Box
				sx={{
					backgroundColor: 'background.paper',
					p: 3,
				}}
			>
				<Typography variant="h4">
					{activity.name}
				</Typography>
			</Box>
			<Divider />

			<Container maxWidth="xl">
				<Card sx={{ my: 3 }}>
					<Tabs
						indicatorColor="primary"
						onChange={handleTabsChange}
						scrollButtons="auto"
						sx={{ px: 3 }}
						textColor="primary"
						value={currentTab}
						variant="scrollable"
					>
						{tabs.map((tab) => (
							<Tab
								key={tab.value}
								label={tab.label}
								value={tab.value}
							/>
						))}
					</Tabs>
					<Divider />
					{currentTab === 'competencies' &&
						<CompetenciesList
							activity={uid}
						/>
					}
				</Card>
			</Container>
		</Box>
	);
}

export default ActivitiesView;
