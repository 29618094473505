import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
// TODO Use SVG
import imgFullLogo from '../assets/images/logos/full-logo.png';

interface FullLogoProps {
	height?: number;
}

export const FullLogo = styled((props: FullLogoProps) => {
	const { height, ...other } = props;
	const width = (height ?? 392) / 392 * 376;

	return (
		<Box
			{...other}
			component="div"
			sx={{
				backgroundImage: `url(${imgFullLogo})`,
				backgroundPosition: 'center center',
				backgroundRepeat: 'no-repeat',
				backgroundSize: 'cover',
				display: 'inline-block',
				height: height,
				width: width,
			}}
		/>
	);
})``;

// TODO Why is this used?
FullLogo.defaultProps = {
	height: 392,
};

FullLogo.propTypes = {
	height: PropTypes.number,
};
