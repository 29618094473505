import { useEffect } from 'react';
import toast from 'react-hot-toast';
import { useParams, useNavigate } from 'react-router-dom';
import axios from '../utils/axios';

// TODO Use another class type like Guard. There is no display in this page, only processing and redirection.
function ConfirmEmailAddress(): null {
	const navigate = useNavigate();
	const { uid, token, hash } = useParams();
	// const theme = useTheme();
	// const image = (theme.palette.mode === 'dark') ? imageDark : imageLight;
	// const mobileDevice = useMediaQuery(theme.breakpoints.down('sm'));

	useEffect(
		() => {
			// TODO Use custom hook?
			axios.put('/confirm-email-address/' + uid + '/' + token + '/' + hash)
				.then(function (response) {
					if (response.status === 200) {
						toast.success(response.data.message);
					} else {
						// Rollbar?
						console.log('Rollbar');
					}
				})
				.catch(function (error) {
					// Rollbar?
					toast.error(error.response.data.message);
					console.log('Rollbar');
				})
				.then(function () {
					navigate('/login');
				});
		},
	);

	return null;
}

export default ConfirmEmailAddress;
