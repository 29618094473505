import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { Link as RouterLink, useParams } from 'react-router-dom';
import {
	Avatar,
	Badge,
	Box,
	Button,
	Divider,
	IconButton,
	Paper,
	styled,
	TextareaAutosize,
	Typography,
} from '@mui/material';
import { ArrowLeft as ArrowLeftIcon } from '../../assets/icons/arrow-left';
import { ThumbDown as ThumbDownIcon, ThumbDownOffAlt as ThumbDownOffAltIcon, ThumbUp as ThumbUpIcon, ThumbUpOffAlt as ThumbUpOffAltIcon } from '@mui/icons-material';
import axios from '../../utils/axios';
import { useAuth } from '../../hooks/useAuth';
import { blue, red } from '@mui/material/colors';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import * as Yup from 'yup';

// TODO Customize language
import 'moment/locale/fr';
moment.locale('fr');

interface Suggestion {
	description: string;
	title: string;
	vote: {
		choice: 'down' | 'up' | undefined;
		count: { down: number, up: number };
	}
}

const CommentTextarea = styled(TextareaAutosize)(
	({ theme }) => ({
		backgroundColor: theme.palette.background.paper,
		border: 'none',
		color: theme.palette.text.primary,
		fontFamily: theme.typography.body1.fontFamily,
		fontSize: theme.typography.body1.fontSize,
		lineHeight: theme.typography.body1.lineHeight,
		outline: 'none',
		resize: 'none',
		width: '100%',
		'&::placeholder': {
			color: theme.palette.text.secondary,
		},
	}),
);

function SuggestionsView(): JSX.Element | null {
	const { uid } = useParams();
	const { t } = useTranslation();
	const [ suggestion, setSuggestion ] = useState<Suggestion | null>(null);
	const [ comments, setComments ] = useState([]);
	const { user } = useAuth();

	useEffect(
		() => {
			// TODO Use custom hook?
			axios.get('/suggestions/' + uid)
				.then(function (response) {
					if (response.status === 200) {
						const { comments: c, ...s } = response.data;
						setSuggestion(s);
						setComments(c);
					} else {
						// Rollbar?
						console.log('Rollbar');
					}
				})
				.catch(function (error) {
					// Rollbar?
					console.log('Rollbar');
				});
			// TODO Find another solution
		}, [], // eslint-disable-line react-hooks/exhaustive-deps
	);

	const formik = useFormik({
		initialValues: {
			// TODO Change for "comment"?
			message: '',
		},
		validationSchema: Yup.object({
			message: Yup
				.string()
				.required(t('yup.message.required')),
		}),
		onSubmit: async (values, helpers): Promise<void> => {
			// TODO Use custom hook?
			axios.put('/suggestions/' + uid + '/comment', {
				message: formik.values.message,
			})
				.then(function (response) {
					if (response.status === 200) {
						// TODO Use reducer instead
						setComments(response.data);
						formik.resetForm();
					} else {
						// Rollbar?
						console.log('Rollbar');
					}
				})
				.catch(function (error) {
					// Rollbar?
					console.log('Rollbar');
				});
		},
	});

	if (suggestion === null) {
		return null;
	}

	const cancelVote = (): void => {
		saveVote();
	};

	const downVote = (): void => {
		saveVote('down');
	};

	const upVote = (): void => {
		saveVote('up');
	};

	const saveVote = (choice?: 'down' | 'up'): void => {
		// TODO Use custom hook?
		axios.put('/suggestions/' + uid + '/vote' + ((choice !== undefined) ? '/' + choice : ''))
			.then(function (response) {
				if (response.status === 200) {
					// TODO Use reducer instead
					const { vote, ...other } = suggestion;
					setSuggestion({ ...other, vote: response.data });
				} else {
					// Rollbar?
					console.log('Rollbar');
				}
			})
			.catch(function (error) {
				// Rollbar?
				console.log('Rollbar');
			});
	};

	return (
		<Box
			sx={{
				backgroundColor: 'background.default',
				display: 'flex',
				flexDirection: 'column',
				flexGrow: 1,
				height: '100%',
				overflowY: 'auto',
			}}
		>
			<Box
				sx={{
					alignItems: 'center',
					backgroundColor: 'background.paper',
					display: 'flex',
					flexShrink: 0,
					p: 2,
				}}
			>
				<IconButton
					component={RouterLink}
					to={'/suggestions'}
				>
					<ArrowLeftIcon fontSize="small" />
				</IconButton>
			</Box>
			<Divider />
			<Box
				sx={{
					backgroundColor: 'background.paper',
					p: 3,
				}}
			>
				<Typography variant="h4">
					{suggestion.title}
				</Typography>
				{suggestion.description &&
					<Typography
						color="textSecondary"
						variant="subtitle1" sx={{ mt: 1 }}
					>
						{suggestion.description}
					</Typography>
				}
				<Box sx={{ mt: 2 }}>
					{
						suggestion.vote.choice === 'up' ? (
							<IconButton
								onClick={cancelVote}
								sx={{ color: blue[600] }}
							>
								<Badge badgeContent={suggestion.vote.count.up} color="info">
									<ThumbUpIcon fontSize='large' />
								</Badge>
							</IconButton>
						) : (
							<IconButton
								onClick={upVote}
								sx={{ '&:hover': { color: blue[600] } }}
							>
								<Badge badgeContent={suggestion.vote.count.up} color="info">
									<ThumbUpOffAltIcon fontSize='large' />
								</Badge>
							</IconButton>
						)
					}
					{
						suggestion.vote.choice === 'down' ? (
							<IconButton
								onClick={cancelVote}
								sx={{ color: red[600] }}
							>
								<Badge badgeContent={suggestion.vote.count.down} color="error">
									<ThumbDownIcon fontSize='large' />
								</Badge>
							</IconButton>
						) : (
							<IconButton
								onClick={downVote}
								sx={{ '&:hover': { color: red[600] } }}
							>
								<Badge badgeContent={suggestion.vote.count.down} color="error">
									<ThumbDownOffAltIcon fontSize='large' />
								</Badge>
							</IconButton>
						)
					}
				</Box>
			</Box>
			<Divider />
			<Box
				sx={{
					p: 3,
				}}
			>
				{/* TODO Think of using Teaxtarea : https://mui.com/joy-ui/react-textarea/ */}
				<Paper
					sx={{
						borderColor: Boolean(formik.touched.message && formik.errors.message) === true ? 'error.main' : undefined,
						flexGrow: 1,
						p: 2,
					}}
					variant="outlined"
				>
					<form
						noValidate
						onSubmit={formik.handleSubmit}
					>
						<Box
							sx={{
								display: 'flex',
							}}
						>
							<Avatar sx={{ width: 24, height: 24 }} />
							<Typography variant='body1' sx={{ fontWeight: 'medium', ml:1 }}>{user?.firstName} {user?.lastName}</Typography>
						</Box>
						{/* TODO LANG */}
						<Typography color="textSecondary" variant='caption' sx={{ fontStyle: 'italic', fontWeight: 'medium' }}>Votre commentaire sera visible par les autres utilisateurs</Typography>
						<CommentTextarea
							minRows={1}
							name="message"
							// TODO Validate onBlur not necessary
							// onBlur={formik.handleBlur}
							onChange={formik.handleChange}
							placeholder={t('pages.suggestions.view.writeComment')}
							value={formik.values.message}
							sx={{
								mt: 1,
								color: Boolean(formik.touched.message && formik.errors.message) === true ? 'error.main' : undefined,
								'&::placeholder': {
									color: Boolean(formik.touched.message && formik.errors.message) === true ? 'error.main' : undefined,
								},
							}}
						/>
						<Box
							sx={{
								alignItems: 'center',
								display: 'flex',
								justifyContent: Boolean(formik.touched.message && formik.errors.message) === true ? 'space-between' : 'flex-end',
								mt: 2,
							}}
						>
							{Boolean(formik.touched.message && formik.errors.message) === true && (
								<Typography color='error'>{formik.errors.message}</Typography>
							)}
							<Button
								sx={{ mr: 1 }}
								type="submit"
								variant="contained"
							>
								{t('pages.suggestions.view.send')}
							</Button>
						</Box>
					</form>
				</Paper>
			</Box>
			{ comments.map((comment: { author: string, date: string, message: string, uid: string }) => (
				<Box
					key={comment.uid}
					sx={{
						pb: 3,
						px: 3,
					}}
				>
					<Paper
						sx={{
							flexGrow: 1,
							p: 2,
						}}
						variant="outlined"
					>
						<Box
							sx={{
								display: 'flex',
								mb: 1,
							}}
						>
							<Avatar sx={{ width: 22, height: 22 }} />
							<Typography variant='body2' sx={{ ml:1 }}>
								<Typography component={'span'} variant='body2' sx={{ fontWeight: 'medium' }}>{comment.author}</Typography>
								{' '}
								{t('pages.suggestions.view.hasWritten')}
								{' '}
								<Typography color="textSecondary" component={'span'} variant='body2'>({moment(comment.date).fromNow()})</Typography>
							</Typography>
						</Box>
						{/* TODO Best way to handle \n? */}
						<Typography variant='body1'  style={{ whiteSpace: 'pre-wrap' }}>{comment.message}</Typography>
					</Paper>
				</Box>
			))}
		</Box>
	);
}

export default SuggestionsView;
