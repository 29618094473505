import { Comment as CommentIcon, ThumbDown as ThumbDownIcon, ThumbDownOffAlt as ThumbDownOffAltIcon, ThumbUp as ThumbUpIcon, ThumbUpOffAlt as ThumbUpOffAltIcon } from '@mui/icons-material';
import { Badge, Box, Chip, IconButton, Typography } from '@mui/material';
import { blue, red } from '@mui/material/colors';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink} from 'react-router-dom';
import axios from '../../utils/axios';

interface SuggestionsItemProps {
	commentCount: number;
	description: string;
	status: string;
	title: string;
	uid: string;
	vote: {
		choice: 'up' | 'down' | undefined;
		count: { up: number, down: number };
	}
}

export const SuggestionsItem: FC<SuggestionsItemProps> = (props) => {
	const { commentCount, description, status, title, uid, vote, ...other } = props;
	const [ voteInfo, setVoteInfo ] = useState(vote);
	const { t } = useTranslation();

	const cancelVote = (): void => {
		saveVote();
	};

	const downVote = (): void => {
		saveVote('down');
	};

	const upVote = (): void => {
		saveVote('up');
	};

	const saveVote = (choice?: 'down' | 'up'): void => {
		// TODO Use custom hook?
		axios.put('/suggestions/' + uid + '/vote' + ((choice !== undefined) ? '/' + choice : ''))
			.then(function (response) {
				if (response.status === 200) {
					setVoteInfo(response.data);
				} else {
					// Rollbar?
					console.log('Rollbar');
				}
			})
			.catch(function (error) {
				// Rollbar?
				console.log('Rollbar');
			});
	};

	return (
		<Box
			sx={{
				alignItems: 'center',
				backgroundColor: 'background.paper',
				borderBottomWidth: 1,
				borderBottomStyle: 'solid',
				borderBottomColor: 'divider',
				display: 'flex',
				'&:hover': {
					backgroundColor: 'action.hover',
				},
			}}
			{...other}
		>
			<Box
				component={RouterLink}
				sx={{
					alignItems: 'center',
					cursor: 'pointer',
					display: 'flex',
					flexGrow: 1,
					flexWrap: {
						xs: 'wrap',
						md: 'nowrap',
					},
					p: 2,
					textDecoration: 'none',
				}}
				to={'/suggestions/' + uid}
			>
				<Box
					sx={{
						flexGrow: 1,
						mx: {
							xs: 0,
							md: 2,
						},
						my: {
							xs: 2,
							md: 0,
						},
						width: {
							xs: '100%',
							md: 'auto',
						},
					}}
				>
					<Box
						sx={{
							alignItems: 'center',
						}}
					>
						<Typography
							color="textPrimary"
							sx={{
								fontWeight: 600,
							}}
							variant="body2"
						>
							{title}
						</Typography>
						{description &&
							<Typography
								color="textSecondary"
								variant="body2"
							>
								{description}
							</Typography>
						}
					</Box>
				</Box>
				<Chip
					label={t('pages.suggestions.item.' + status + '.text')}
					size="small"
					style={{ backgroundColor: t('pages.suggestions.item.' + status + '.color') }}
				/>
			</Box>
			<Box
				sx={{
					alignItems: 'center',
					display: {
						md: 'flex',
						xs: 'none',
					},
					mr: {
						xs: 0,
						md: 4,
					},
				}}
			>
				{
					voteInfo.choice === 'up' ? (
						<IconButton
							onClick={cancelVote}
							sx={{ color: blue[600] }}
						>
							<Badge badgeContent={voteInfo.count.up} color="info">
								<ThumbUpIcon />
							</Badge>
						</IconButton>
					) : (
						<IconButton
							onClick={upVote}
							sx={{ '&:hover': { color: blue[600] } }}
						>
							<Badge badgeContent={voteInfo.count.up} color="info">
								<ThumbUpOffAltIcon />
							</Badge>
						</IconButton>
					)
				}
				{
					voteInfo.choice === 'down' ? (
						<IconButton
							onClick={cancelVote}
							sx={{ color: red[600] }}
						>
							<Badge badgeContent={voteInfo.count.down} color="error">
								<ThumbDownIcon />
							</Badge>
						</IconButton>
					) : (
						<IconButton
							onClick={downVote}
							sx={{ '&:hover': { color: red[600] } }}
						>
							<Badge badgeContent={voteInfo.count.down} color="error">
								<ThumbDownOffAltIcon />
							</Badge>
						</IconButton>
					)
				}
				<Badge badgeContent={commentCount} color="warning" sx={{ mx: 1 }}>
					<CommentIcon sx={{ color: 'neutral.400' }} />
				</Badge>
			</Box>
		</Box>
	);
};
