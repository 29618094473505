import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import {
	Avatar,
	Box,
	Button,
	Card,
	Container,
	Divider,
	Grid,
	IconButton,
	Menu,
	MenuItem,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Typography,
} from '@mui/material';
import moment from 'moment';
import { Trans, useTranslation } from 'react-i18next';
import axios from '../../utils/axios';
import { DotsHorizontal as DotsHorizontalIcon } from '../../assets/icons/dots-horizontal';
import { AuthGuard } from '../../components/AuthGuard';
import { ConfirmDialog } from '../../components/ConfirmDialog';
import { Scrollbar } from '../../components/Scrollbar';
import { SeverityPill } from '../../components/SeverityPill';
import AddIcon from '@mui/icons-material/Add';

interface Apprentice {
	avatar?: {
		color?: string,
		data: string,
	},
	birthDate?: string;
	cycle?: string;
	firstName: string;
	lastName: string;
	uid: string;
}

function ApprenticesList(): JSX.Element | null {
	const navigate = useNavigate();
	const { t } = useTranslation();
	const [ loaded, setLoaded ] = useState(false);
	const [ menuInfo, setMenuInfo ] = useState<{element: HTMLElement, uid: string} | null>(null);
	const [ apprentices, setApprentices ] = useState([]);
	const [ dialogInfo, setDialogInfo ] = useState<{open: boolean, apprentice?: Apprentice}>({open: false});

	const handleMenuOpen = (uid: string, event: React.MouseEvent<HTMLButtonElement>) => {
		setMenuInfo({element: event.currentTarget, uid: uid});
	};

	const handleMenuClose = (): void => {
		setMenuInfo(null);
	};

	const handleEdit = (): void => {
		// TODO Rollbar?
		navigate('/apprentices/' + menuInfo?.uid);
	};

	const handleDelete = (): void => {
		setDialogInfo({ open: false, apprentice: dialogInfo.apprentice });
		// TODO Use custom hook?
		axios.delete('/apprentices/' + dialogInfo.apprentice?.uid)
			.then(function (response) {
				if (response.status === 200) {
					toast.success(response.data.message);
					loadApprentices();
				} else {
					// Rollbar?
					console.log('Rollbar');
				}
			})
			.catch(function (error) {
				// Rollbar?
				console.log('Rollbar');
			});
	};

	const handleDeleteDialog = (): void => {
		handleMenuClose();
		setDialogInfo({open: true, apprentice: apprentices.find((apprentice: Apprentice) => apprentice.uid === menuInfo?.uid)});
	};

	const loadApprentices = () => {
		// TODO Use custom hook?
		axios.get('/apprentices')
			.then(function (response) {
				if (response.status === 200) {
					setApprentices(response.data);
					if (!loaded) setLoaded(true);
				} else {
					// Rollbar?
					console.log('Rollbar');
				}
			})
			.catch(function (error) {
				// Rollbar?
				console.log('Rollbar');
			});
	};

	useEffect(
		() => {
			loadApprentices();
			// TODO Find another solution
		}, [], // eslint-disable-line react-hooks/exhaustive-deps
	);

	if (!loaded) {
		return null;
	}

	return (
		// TODO Put guard before Dashboard displays
		<AuthGuard>
			<Box
				component="main"
				sx={{
					flexGrow: 1,
					py: 8,
				}}
			>
				<Container maxWidth="md">
					<Grid
						container
						justifyContent="space-between"
					>
						<Grid item>
							<Typography variant="h4">
								{ t('pages.apprentices.list.title') }
							</Typography>
						</Grid>
						<Grid item>
							<Button
								component={RouterLink}
								startIcon={<AddIcon fontSize="small" />}
								to="/apprentices/add"
								variant="contained"
							>
								{ t('pages.apprentices.list.add') }
							</Button>
						</Grid>
					</Grid>
					<Divider sx={{ my: 3 }} />
					{apprentices.length === 0 ? (
						<Card
							sx={{
								background: 'none',
								border: 10,
								borderColor: 'background.paper',
								borderStyle: 'dashed',
								p: 4,
								textAlign: 'center',
							}}
						>
							<Typography
								variant="h5"
							>
								{ t('pages.apprentices.list.noApprentice') }
							</Typography>
							<Button
								color="secondary"
								component={RouterLink}
								size="large"
								startIcon={<AddIcon />}
								to="/apprentices/add"
								variant="contained"
								sx={{ mt: 5 }}
							>
								{ t('pages.apprentices.list.addApprentice') }
							</Button>
						</Card>
					) : (
						<Card>
							<Scrollbar>
								<Table sx={{ minWidth: 400 }}>
									<TableHead>
										<TableRow>
											<TableCell>
												{ t('pages.apprentices.list.name') }
											</TableCell>
											<TableCell>
												{ t('pages.apprentices.list.age') }
											</TableCell>
											<TableCell>
												{ t('pages.apprentices.list.cycle') }
											</TableCell>
											<TableCell />
										</TableRow>
									</TableHead>
									<TableBody>
										{ apprentices.map(function(apprentice: Apprentice) {
											return (
												<TableRow key={apprentice.uid}>
													<TableCell>
														<Box
															sx={{
																alignItems: 'center',
																display: 'flex',
															}}
														>
															<Avatar
																src={apprentice.avatar && apprentice.avatar.data}
																sx={{
																	bgcolor: apprentice.avatar?.color,
																	height: 40,
																	width: 40,
																	mr: 1,
																}}
															/>
															<div>
																<Typography variant="subtitle2">
																	{apprentice.firstName}
																</Typography>
																<Typography
																	color="textSecondary"
																	variant="body2"
																>
																	{apprentice.lastName}
																</Typography>
															</div>
														</Box>
													</TableCell>
													<TableCell>
														{
															(apprentice.birthDate !== undefined) ?
																<SeverityPill>{t('pages.apprentices.list.years', { count: moment().diff(moment(apprentice.birthDate), 'years')})}</SeverityPill> :
																<SeverityPill color="warning">{t('pages.apprentices.list.ageNotDefined')}</SeverityPill>
														}
													</TableCell>
													<TableCell>
														{
															(apprentice.cycle !== undefined) ?
																<SeverityPill color="secondary">{t(`pages.apprentices.list.cycles.${apprentice.cycle}`)}</SeverityPill> :
																<SeverityPill color="warning">{t('pages.apprentices.list.cycleNotDefined')}</SeverityPill>
														}
													</TableCell>
													<TableCell align="right">
														<IconButton onClick={ (e) => handleMenuOpen(apprentice.uid, e) }>
															<DotsHorizontalIcon fontSize="small" />
														</IconButton>
													</TableCell>
												</TableRow>
											);
										})}
									</TableBody>
								</Table>
							</Scrollbar>
						</Card>
					)}
				</Container>
			</Box>
			<Menu
				anchorEl={menuInfo?.element}
				anchorOrigin={{
					horizontal: 'left',
					vertical: 'bottom',
				}}
				keepMounted
				onClose={handleMenuClose}
				open={Boolean(menuInfo)}
			>
				<MenuItem onClick={handleEdit}>
					{ t('pages.apprentices.list.edit') }
				</MenuItem>
				<MenuItem onClick={handleDeleteDialog}>
					{ t('pages.apprentices.list.delete') }
				</MenuItem>
			</Menu>
			<ConfirmDialog
				confirmText={ t('pages.apprentices.list.deleteButton') }
				content={
					<Trans
						i18nKey="pages.apprentices.list.deleteText"
						values={{ name: dialogInfo.apprentice?.firstName +' '+ dialogInfo.apprentice?.lastName }}
						components={[<></>,<Typography component="span" color="secondary"></Typography>,<></>]}
					/>
				}
				onClose={() => setDialogInfo({ open: false, apprentice: dialogInfo.apprentice })}
				onConfirm={handleDelete}
				open={dialogInfo.open}
			/>
		</AuthGuard>
	);
}

export default ApprenticesList;
