import { ReactNode, useEffect, useMemo } from 'react';
import type { FC } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import type { TFunction } from 'react-i18next';
import { HowToVote as HowToVoteIcon, SportsEsports as SportsEsportsIcon } from '@mui/icons-material';
import { Box, Button, Divider, Drawer, Typography, useMediaQuery } from '@mui/material';
import type { Theme } from '@mui/material';
import { Home as HomeIcon } from '../assets/icons/home';
// import { Logo } from '../components/Logo';
import { CompactLogo } from '../components/CompactLogo';
import { Scrollbar } from '../components/Scrollbar';
import { DashboardSidebarSection } from './DashboardSidebarSection';
import { Users } from '../assets/icons/users';

interface DashboardSidebarProps {
	onClose: () => void;
	open: boolean;
}

interface Item {
	title: string;
	children?: Item[];
	chip?: ReactNode;
	icon?: ReactNode;
	path?: string;
}

interface Section {
	title: string;
	items: Item[];
}

const getSections = (t: TFunction): Section[] => [
	{
		title: t('layouts.main.sidebar.general'),
		items: [
			{
				title: t('layouts.main.sidebar.dashboard'),
				path: '/dashboard',
				icon: <HomeIcon fontSize="small" />,
			},
		],
	},
	{
		title: t('layouts.main.sidebar.community'),
		items: [
			{
				title: t('layouts.main.sidebar.activities'),
				path: '/activities',
				icon: <SportsEsportsIcon fontSize="small" />,
			},
		],
	},
	{
		title: 'Gestion',
		items: [
			{
				title: t('layouts.main.sidebar.apprentices'),
				path: '/apprentices',
				icon: <Users fontSize="small" />,
			},
		],
	},
];

export const DashboardSidebar: FC<DashboardSidebarProps> = (props) => {
	const { onClose, open } = props;
	// const router = useRouter();
	const location = useLocation();

	const { t } = useTranslation();
	const lgUp = useMediaQuery(
		(theme: Theme) => theme.breakpoints.up('lg'),
		{
			noSsr: true,
		},
	);
	const sections = useMemo(() => getSections(t), [t]);

	const handlePathChange = () => {
		// if (!router.isReady) {
		// 	return;
		// }

		// if (open) {
		// 	onClose?.();
		// }
	};

	useEffect(
		handlePathChange,
		// eslint-disable-next-line react-hooks/exhaustive-deps
		// [router.isReady, router.asPath]
	);

	const content = (
		<>
			<Scrollbar
				sx={{
					height: '100%',
					'& .simplebar-content': {
						height: '100%',
					},
				}}
			>
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'column',
						height: '100%',
					}}
				>
					<div>
						<Box
							sx={{
								lineHeight: 0,
								p: 3,
							}}>
							<RouterLink
								to="/dashboard"
							>
								{/*
								<Logo
									sx={{
										height: 42,
										width: 42,
									}}
								/>
								*/}
								<CompactLogo height={42} />
							</RouterLink>
						</Box>
					</div>
					<Divider
						sx={{
							borderColor: '#2D3748', // dark divider
							mb: 3,
						}}
					/>
					<Box sx={{ flexGrow: 1 }}>
						{sections.map((section) => (
							<DashboardSidebarSection
								key={section.title}
								path={location.pathname}
								sx={{
									mt: 2,
									'& + &': {
										mt: 2,
									},
								}}
								{...section}
							/>
						))}
					</Box>
					<Divider
						sx={{
							borderColor: '#2D3748', // dark divider
						}}
					/>
					<Box sx={{ p: 2, textAlign: 'center' }}>
						<Typography
							color="neutral.100"
							variant="subtitle2"
						>
							{t('layouts.main.sidebar.ideaToSuggest')}
						</Typography>
						<Typography
							color="neutral.500"
							variant="body2"
						>
							{t('layouts.main.sidebar.discussHere')}
						</Typography>
						<Button
							color="secondary"
							component={RouterLink}
							startIcon={<HowToVoteIcon />}
							to="/suggestions"
							fullWidth
							sx={{ mt: 1 }}
							variant="outlined"
						>
							{t('layouts.main.sidebar.suggestionBox')}
						</Button>
					</Box>
					<Divider
						sx={{
							borderColor: '#2D3748', // dark divider
						}}
					/>
					<Button
						component={RouterLink}
						sx={{ borderRadius: 0, p: 1, textAlign: 'center' }}
						target='_blank'
						to="/changelog"
					>
						<Typography
							color="neutral.400"
							variant="subtitle2"
						>
							Changelog
						</Typography>
					</Button>
				</Box>
			</Scrollbar>
		</>
	);

	if (lgUp) {
		return (
			<Drawer
				anchor="left"
				open
				PaperProps={{
					sx: {
						backgroundColor: 'neutral.900',
						borderRightColor: 'divider',
						borderRightStyle: 'solid',
						borderRightWidth: (theme) => theme.palette.mode === 'dark' ? 1 : 0,
						color: '#FFFFFF',
						width: 280,
					},
				}}
				variant="permanent"
			>
				{content}
			</Drawer>
		);
	}

	return (
		<Drawer
			anchor="left"
			onClose={onClose}
			open={open}
			PaperProps={{
				sx: {
					backgroundColor: 'neutral.900',
					color: '#FFFFFF',
					width: 280,
				},
			}}
			sx={{ zIndex: (theme) => theme.zIndex.appBar + 100 }}
			variant="temporary"
		>
			{content}
		</Drawer>
	);
};

DashboardSidebar.propTypes = {
	onClose: PropTypes.func.isRequired,
	open: PropTypes.bool.isRequired,
};
