import moment from 'moment';
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
import { Collections as CollectionsIcon, Delete as DeleteIcon, DoDisturb as DoDisturbIcon } from '@mui/icons-material';
import { Avatar, Box, Breadcrumbs, Button, ButtonBase, Card, CardContent, Container, FormControl, Grid, InputAdornment, InputLabel, Link, MenuItem, Select, TextField, Typography } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import axios from '../../utils/axios';
import { AuthGuard } from '../../components/AuthGuard';

// TODO Declare Avatar an use alias for MUI component instead?
type AvatarData = {
	color: string,
	data: string,
	uid: string,
};

function ApprenticesEdit(): JSX.Element | null {
	const [ loaded, setLoaded ] = useState(false);
	const [ avatars, setAvatars ] = useState<AvatarData[] | undefined>(undefined);
	const avatarFileInput = useRef<HTMLInputElement>(null);
	const [ selectedAvatar, setSelectedAvatar ] = useState<{data?: string, file?: File, uid?: string} | undefined>(undefined);
	const [ fullName, setFullName ] = useState('');
	const navigate = useNavigate();
	const { uid } = useParams();
	const { t } = useTranslation();

	const formik = useFormik({
		initialValues: {
			birthDate: null as moment.Moment | null,
			cycle: {
				code: '',
				from: null as moment.Moment | null,
				to: null as moment.Moment | null,
			},
			firstName: '',
			lastName: '',
		},
		validationSchema: Yup.object({
			cycle: Yup.object({
				from: Yup.mixed()
					.test('test-from-date-required', t('yup.cycle.from.required'),
						function(value: moment.Moment | null) {
							if (formik.values.cycle.code === '') return true;
							return value !== null;
						})
					.test('test-from-before-to', t('yup.cycle.from.beforeTo'),
						function(value: moment.Moment | null) {
							const to = formik.values.cycle.to?.toDate().getTime() as number;
							if ((value !== null) &&
								(value.isValid()) &&
								(formik.values.cycle.to !== undefined) &&
								(formik.values.cycle.to !== null) &&
								(formik.values.cycle.to.isValid())
							) return value.toDate().getTime() < to;
							return true;
						},
					),
				to: Yup.mixed()
					.test('test-to-date-required', t('yup.cycle.to.required'),
						function(value: moment.Moment | null) {
							if (formik.values.cycle.code === '') return true;
							return value !== null;
						})
					.test('test-to-after-from', t('yup.cycle.to.afterFrom'),
						function(value: moment.Moment | null) {
							const from = formik.values.cycle.from?.toDate().getTime() as number;
							if ((value !== null) &&
									(value.isValid()) &&
									(formik.values.cycle.from !== undefined) &&
									(formik.values.cycle.from !== null) &&
									(formik.values.cycle.from.isValid())
							) return value.toDate().getTime() > from;
							return true;
						},
					),
			}),
			firstName: Yup
				.string()
				.max(255)	// TODO Lang
				.required(t('yup.firstName.required')),
			lastName: Yup
				.string()
				.max(255)	// TODO Lang
				.required(t('yup.lastName.required')),
		}),
		onSubmit: async (values, helpers): Promise<void> => {
			let data;
			if (selectedAvatar?.file) {
				data = new FormData();
				data.append('avatar', selectedAvatar.file);
				if (values.birthDate !== null) {
					data.append('birthDate', moment(values.birthDate).format('YYYY-MM-DD'));
				}
				data.append('firstName', values.firstName);
				data.append('lastName', values.lastName);
				if (values.cycle.code !== '') {
					data.append('cycle', JSON.stringify({
						code: values.cycle.code,
						from: moment(values.cycle.from).format('YYYY-MM-DD'),
						to: moment(values.cycle.to).format('YYYY-MM-DD'),
					}));
				}
			} else {
				data = {
					avatar: (selectedAvatar?.data) ? '' : selectedAvatar?.uid,
					birthDate: (values.birthDate !== null) ? moment(values.birthDate).format('YYYY-MM-DD') : undefined,
					firstName: values.firstName,
					lastName: values.lastName,
					cycle: values.cycle.code === '' ? undefined : {
						code: values.cycle.code,
						from: moment(values.cycle.from).format('YYYY-MM-DD'),
						to: moment(values.cycle.to).format('YYYY-MM-DD'),
					},
				};
			}
			// TODO Use custom hook?
			axios.put('/apprentices/' + uid, data)
				.then(function (response) {
					if (response.status === 200) {
						toast.success(response.data.message);
						navigate('/apprentices');
					} else {
						// Rollbar?
						console.log('Rollbar');
					}
				})
				.catch(function (error) {
					// Rollbar?
					console.log('Rollbar');
				});
		},
	});

	useEffect(
		() => {
			// TODO Use custom hook?
			axios.get('/apprentices/avatars')
				.then(function (response) {
					if (response.status === 200) {
						setAvatars(response.data);
						// TODO Use custom hook?
						axios.get('/apprentices/' + uid)
							.then(function (response) {
								if (response.status === 200) {
									setFullName(response.data.firstName + ' ' + response.data.lastName);
									// TODO setFieldValue?
									formik.values.birthDate = (response.data.birthDate !== undefined) ? moment(response.data.birthDate) : null;
									formik.values.firstName = response.data.firstName;
									formik.values.lastName = response.data.lastName;
									if (response.data.cycle !== undefined) {
										formik.values.cycle.code = response.data.cycle.code;
										formik.values.cycle.from = moment(response.data.cycle.from);
										formik.values.cycle.to = moment(response.data.cycle.to);
									}
									setSelectedAvatar(response.data.avatar);
									setLoaded(true);
								} else {
									// Rollbar?
									console.log('Rollbar');
								}
							})
							.catch(function (error) {
								// Rollbar?
								console.log('Rollbar');
							});
					} else {
						// Rollbar?
						console.log('Rollbar');
					}
				})
				.catch(function (error) {
					// Rollbar?
					console.log('Rollbar');
				});
			// TODO Find another solution
		}, [], // eslint-disable-line react-hooks/exhaustive-deps
	);

	useEffect(
		() => {
			formik.validateForm();
		}, [formik.values.cycle], // eslint-disable-line react-hooks/exhaustive-deps
	);

	const handleFileInput = (event: ChangeEvent<HTMLInputElement>) => {
		if (event.target.files === null) {
			// TODO Rollbar
			return;
		}
		const file = event.target.files[0] as File;
		if (['image/gif', 'image/jpeg', 'image/png'].includes(file.type) !== true) {
			if (avatarFileInput.current !== null) avatarFileInput.current.value = '';
			// TODO Lang
			toast.error('Seuls les fichiers au format GIF, JPG et PNG sont acceptés.');
			return;
		} else if (file.size > 5 * 1024 * 1024) {
			if (avatarFileInput.current !== null) avatarFileInput.current.value = '';
			// TODO Lang
			toast.error('La taille du fichier ne doit pas dépasser 5 Mo.');
			return;
		}
		setSelectedAvatar({ file: file });
	};

	const handleCancelFileInput = () => {
		if (avatarFileInput.current !== null) avatarFileInput.current.value = '';
	};

	if (!loaded) {
		return null;
	}

	return (
		// TODO Put guard before Dashboard displays
		<AuthGuard>
			<Box
				component="main"
				sx={{
					flexGrow: 1,
					py: 8,
				}}
			>
				<Container maxWidth="md">
					<Box sx={{ mb: 3 }}>
						<Typography variant="h4">
							{ t('pages.apprentices.edit.title') }
						</Typography>
						<Breadcrumbs
							separator="/"
							sx={{ mt: 1 }}
						>
							<Link
								component={RouterLink}
								to="/apprentices"
								variant="subtitle2"
							>
								{ t('pages.apprentices.list.title') }
							</Link>
							<Typography
								color="textSecondary"
								variant="subtitle2"
							>
								{fullName}
							</Typography>
						</Breadcrumbs>
					</Box>
					<form
						noValidate
						onSubmit={formik.handleSubmit}
					>
						<Card>
							<CardContent>
								<Grid
									container
									spacing={3}
								>
									<Grid
										item
										md={4}
										xs={12}
									>
										<Typography variant="h6">
											{ t('pages.apprentices.edit.basicInformation') }
										</Typography>
									</Grid>
									<Grid
										item
										md={8}
										xs={12}
									>
										<TextField
											error={Boolean(formik.touched.firstName && formik.errors.firstName)}
											fullWidth
											helperText={formik.touched.firstName && formik.errors.firstName}
											label={ t('pages.apprentices.edit.firstName') }
											name="firstName"
											onBlur={formik.handleBlur}
											onChange={formik.handleChange}
											required
											value={formik.values.firstName}
										/>
										<TextField
											error={Boolean(formik.touched.lastName && formik.errors.lastName)}
											fullWidth
											helperText={formik.touched.lastName && formik.errors.lastName}
											label={ t('pages.apprentices.edit.lastName') }
											name="lastName"
											onBlur={formik.handleBlur}
											sx={{ mt: 2 }}
											onChange={formik.handleChange}
											required
											value={formik.values.lastName}
										/>
										<LocalizationProvider dateAdapter={AdapterMoment}>
											<DatePicker
												inputFormat="YYYY-MM-DD"
												label={ t('pages.apprentices.edit.birthDate') }
												value={formik.values.birthDate}
												onChange={value => formik.setFieldValue('birthDate', value)}
												renderInput={(params) => <TextField sx={{ mt: 2 }} {...params} />}
											/>
										</LocalizationProvider>
									</Grid>
								</Grid>
							</CardContent>
						</Card>
						<Card sx={{ mt: 3 }}>
							<CardContent>
								<Grid
									container
									spacing={3}
								>
									<Grid
										item
										md={4}
										xs={12}
									>
										<Typography variant="h6">
											{ t('pages.apprentices.edit.currentCycle') }
										</Typography>
									</Grid>
									<Grid
										item
										md={8}
										xs={12}
									>
										<FormControl fullWidth>
											<InputLabel id="cycle-label">{ t('pages.apprentices.edit.cycle') }</InputLabel>
											<Select
												labelId="cycle-label"
												id="demo-simple-select"
												value={formik.values.cycle.code}
												label={ t('pages.apprentices.edit.cycle') }
												onChange={(event) => { if (event.target.value === '') { formik.setFieldValue('cycle.from', null); formik.setFieldValue('cycle.to', null); formik.setFieldTouched('cycle.from', false); formik.setFieldTouched('cycle.to', false); } formik.setFieldValue('cycle.code', event.target.value); }}
											>
												{/* TODO Lang / string */}
												<MenuItem value={''}><em>Aucun</em></MenuItem>
												<MenuItem value={'elementary_1'}>Primaire — Cycle 1</MenuItem>
												<MenuItem value={'elementary_2'}>Primaire — Cycle 2</MenuItem>
												<MenuItem value={'elementary_3'}>Primaire — Cycle 3</MenuItem>
												<MenuItem value={'secondary_1'}>Secondaire — Cycle 1</MenuItem>
												<MenuItem value={'secondary_2'}>Secondaire — Cycle 2</MenuItem>
											</Select>
										</FormControl>
										{formik.values.cycle.code !== '' &&
											<LocalizationProvider dateAdapter={AdapterMoment}>
												<Grid
													container
													spacing={2}
													sx={{ mt: 0 }}
												>
													<Grid
														item
														md={6}
														xs={12}
													>
														<DatePicker
															inputFormat="YYYY-MM-DD"
															label={ t('pages.apprentices.edit.cycleFrom') }
															value={formik.values.cycle.from}
															onChange={value => { formik.setFieldTouched('cycle.from', true); formik.setFieldValue('cycle.from', value); }}
															renderInput={(params) => {
																if (params.error !== true) {
																	params.error = Boolean(formik.touched.cycle?.from && formik.errors.cycle?.from);
																}
																params.helperText = formik.touched.cycle?.from && formik.errors.cycle?.from;

																return <TextField fullWidth {...params} />;
															}}
														/>
													</Grid>
													<Grid
														item
														md={6}
														xs={12}
													>
														<DatePicker
															inputFormat="YYYY-MM-DD"
															label={ t('pages.apprentices.edit.cycleTo') }
															value={formik.values.cycle.to}
															onChange={value => { formik.setFieldTouched('cycle.to', true); formik.setFieldValue('cycle.to', value); }}
															renderInput={(params) => {
																if (params.error !== true) {
																	params.error = Boolean(formik.touched.cycle?.to && formik.errors.cycle?.to);
																}
																params.helperText = formik.touched.cycle?.to && formik.errors.cycle?.to;

																return <TextField fullWidth {...params} />;
															}}
														/>
													</Grid>
												</Grid>
											</LocalizationProvider>
										}
									</Grid>
								</Grid>
							</CardContent>
						</Card>
						<Card sx={{ mt: 3 }}>
							<CardContent>
								<Grid
									container
									spacing={3}
								>
									<Grid
										item
										md={4}
										xs={12}
									>
										<Typography variant="h6">
											{/* TODO Lang */}
											{ t('Avatar') }
										</Typography>
									</Grid>
									<Grid
										item
										md={8}
										xs={12}
									>
										<Grid container spacing={2}>
											<Grid item>
												<Avatar
													component={ButtonBase}
													onClick={ () => { setSelectedAvatar(undefined); handleCancelFileInput(); } }
													sx={{
														bgcolor: (selectedAvatar === undefined) ? 'neutral.800' : '#444',
														border: (selectedAvatar === undefined) ? '3px solid' : 'initial',
														borderColor: (selectedAvatar === undefined) ? 'text.primary' : 'initial',
														width: 85,
														height: 85,
														'&:hover': {
															backgroundColor: 'neutral.800',
														},
													}}
												>
													<DoDisturbIcon sx={{ fontSize: '40px' }} />
												</Avatar>
											</Grid>
											<Grid item>
												<Avatar
													component={ButtonBase}
													onClick={() => {
														if (selectedAvatar?.file === undefined && selectedAvatar?.data === undefined) {
															if (avatarFileInput.current !== null) avatarFileInput.current.click();
														} else {
															setSelectedAvatar(undefined);
															handleCancelFileInput();
														}
													}}
													sx={{
														bgcolor: '#444',
														width: 85,
														height: 85,
														'&:hover': {
															backgroundColor: 'neutral.800',
														},
													}}
												>
													{(selectedAvatar?.file === undefined && selectedAvatar?.data === undefined) && <CollectionsIcon sx={{ fontSize: '40px' }} />}

													{(selectedAvatar?.file !== undefined || selectedAvatar?.data !== undefined) &&
														<>
															<Avatar
																// BUG Manage animated GIF files
																src={(selectedAvatar?.file !== undefined) ? URL.createObjectURL(selectedAvatar.file) : selectedAvatar.data}
																sx={{
																	border: '3px solid',
																	borderColor: 'text.primary',
																	height: 85,
																	position: 'absolute',
																	width: 85,
																}}
															/>
															<Avatar
																sx={{
																	bgcolor: 'rgba(0, 0, 0, 0.6)',
																	border: '3px solid',
																	borderColor: 'text.primary',
																	height: 85,
																	position: 'absolute',
																	opacity: 0,
																	width: 85,
																	'&:hover': {
																		opacity: 1,
																	},
																}}
															>
																<DeleteIcon sx={{ fontSize: '40px' }} />
															</Avatar>
														</>
													}
												</Avatar>
											</Grid>
											{ (avatars !== undefined) && avatars.map(function(avatar: AvatarData) {
												return (
													<Grid key={avatar.uid} item>
														<Avatar
															component={ButtonBase}
															onClick={ () => { setSelectedAvatar({ uid: avatar.uid }); handleCancelFileInput(); } }
															src={avatar.data}
															sx={{
																bgcolor: (selectedAvatar?.uid === avatar.uid) ? avatar.color : '#444',
																border: (selectedAvatar?.uid === avatar.uid) ? '3px solid' : 'initial',
																padding: (selectedAvatar?.uid === avatar.uid) ? 'initial' : '3px',
																width: 85,
																height: 85,
																'&:hover': {
																	backgroundColor: avatar.color,
																} }}
														/>
													</Grid>
												);
											})}
											<Box sx={{ display: 'none' }}>
												<input
													type="file"
													ref={avatarFileInput}
													onChange={handleFileInput}
												/>
											</Box>
										</Grid>
									</Grid>
								</Grid>
							</CardContent>
						</Card>
						<Box
							sx={{
								display: 'flex',
								flexWrap: 'wrap',
								justifyContent: 'flex-end',
								mx: -1,
								mb: -1,
								mt: 3,
							}}
						>
							<Button
								component={RouterLink}
								sx={{ m: 1 }}
								to="/apprentices"
								variant="outlined"
							>
								{ t('pages.apprentices.edit.back') }
							</Button>
							<Button
								sx={{ m: 1 }}
								type="submit"
								variant="contained"
							>
								{ t('pages.apprentices.edit.submit') }
							</Button>
						</Box>
					</form>
				</Container>
			</Box>
		</AuthGuard>
	);
}

export default ApprenticesEdit;
